import { Autocomplete, Avatar, Box, CardMedia, Grid, Icon, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { FileUploadBox, SoftButton, StyledFilterFormControl, StyledFormControl, StyledFormControlLabel, StyledTextField } from '../../../theme/StyledEle'
import { FormControl } from '@mui/material'
import { RiUploadCloud2Fill } from 'react-icons/ri'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCategoryListQuery, useCreateTicketMutation, useGetVehicleListByOrgMutation, useGetVehicleListMutation } from '../../../store/slice/Helpdesk/HelpDeskSlice'
import { AiOutlineDelete } from 'react-icons/ai'
import { BsImageAlt } from 'react-icons/bs'
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation } from '../../../store/slice/ApiSlice'

import { snackService } from '../../../store/slice/ControlsSlice'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ScaleLoader from 'react-spinners/ScaleLoader'
import SingleDropdownHelpDesk from "../../NewDropdown/SingleDropdownHelpDesk";
import { useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import subDays from "date-fns/subDays";

import moment from "moment";

function CreateTicket(props) {

  const { getDrop, createLoading, createTicketNew } = props

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;
  let value = 0;

  //Create State
  const [editorValue, setEditorValue] = useState('');
  const [category, setCategory] = useState(resellerId === 110224 ? 'Vehicle Breakdown' : 'Service');
  const [type, setType] = useState(0);
  const [status, setStatus] = useState('Open');
  const [servity, setServity] = useState('Medium');
  const [title, setTitle] = useState(null);
  const [incidentDateTime, setIncidentDateTime] = useState(moment(new Date()).format("yyyy-MM-DD HH:mm:ss"));
  const [incidentCloseDateTime, setIncidentCloseDateTime] = useState(moment(new Date()).format("yyyy-MM-DD HH:mm:ss"));
  const [incidentLocation, setIncidentLocation] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [tempDate, setTempDate] = useState();
  
  const [vehicle, setVehicle] = useState("-1");
  const [customer, setCustomer] = useState("-1");
  const [fleet, setFleet] = useState("-1");

  const [region, setRegion] = useState("-1");
  const [dealer, setDealer] = useState("-1");

  const [typeList, setTypeList] = useState(null);
  //Hide State
  const [serviceShow, setServiceShow] = useState(false);
  const [issueShow, setIssueShow] = useState(false);

  //Image Upload array
  const [upload, setUpload] = useState([]);
  const [filedet, setFile] = useState([]);

  //Customer & Fleet List
  const [customerLists, setCustomerLists] = useState([]);
  const [fleetList, setfleetList] = useState();
  const [load, setLoad] = useState(false);
  //State for Dropdowns
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);

  const [customerList, setCustomerList] = useState(null);
  const [routeList, setRouteList] = useState(null);
  let CommonPayload = {
    resellerId: resellerId,
  };

  //get Vehicle List
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selectedVeh, setSelectedVeh] = useState(null);
  const [closeType, setCloseType] = useState(false);

  let vehicleNo = [];
  let vehicleId = [];
  let vehicleChassisNo = [];
  const [skip, setSkip] = useState(true);
  const { data:categoryListData, categorySuccess } = useCategoryListQuery();
  //const [getCategoryList, { data: categoryListData, isSuccess: categorySuccess }] = useCategoryListQuery();
  //const [ createTicketNew, {isLoading:createLoading,} ] = useCreateTicketMutation();

  const [getVehicleLst, { data: vehiclesData }] = useGetVehicleListMutation();

  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();

  //const [getCustomerList, {data:dropData, isSuccess:customerSuccess}] = useGetDropdownListMutation();

  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();

  const [getVehicleByOrg] = useGetVehicleListByOrgMutation();
  const [validate, setValidate] = useState(false);
  const [allOption, setAllOption] = useState(true);
  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);

  //Drodowns
  let Getcustomer = {
    "resellerId": resellerId,
    "limit": 500,
    "searchText": "",
    'dealerId': 0
  }
  //Customer Login Payload
  let CustomerPayload = {
    resellerId: resellerId, // Set Login Customer ID
    value: "fleet",
    dealerId: "0",
    customerId: orgId,
    searchText: "",
  };

  const getDropdownLists = async (value, who) => {
    if (who === 'customer') {
      setCustomer(value);
      if (value !== 0) {
        Getcustomer.customerId = value;
        Getcustomer.value = 'fleet';
        const fleetList = await getCustomerList(Getcustomer);
        setfleetList(fleetList.data.data.content);
        setFleet("-1");
        setVehicle("-1");
        setVehicleList([]);
      } else {
        setCustomer("-1");
        setFleet("-1");
        setfleetList(null);
        setVehicle("-1");
        setVehicleList([]);

      }

    } else if (who === 'fleet') {
      if (value !== "-1" && value !== 0) {
        setFleet(value);
        setVehicleList([])
        Getcustomer.value = 'fleet';
        Getcustomer.orgId = value;
        const vehicleList = await getVehicleByOrg(Getcustomer);
        setVehicleList(vehicleList.data.dataresult);
      } else {
        setFleet("-1");
        setVehicle("-1");
        setVehicleList([]);
      }

    }
  }


  let categoryPayload = {
    "isMobile": false
  };

  useEffect(async () => {
    setCustomer("-1");
    setFleet("-1");
    setVehicle("-1");
    setVehicleList([]);
    setfleetList(null);
    if (category !== 0) {
      const typeData = categoryListData?.data?.type.filter((item) => item.title !== 'Pause' && item.title !== 'Resume' && item.category === 'Service' ? item.category === category : (item.category === 'Issue' || item.category === 'Feature Request' || item.category === 'Vehicle Breakdown' || item.category === 'Charger Breakdown' || item.category === 'General Complaint' || item.category === 'Scheduled Service' || item.category === 'Telematics Issue' || item.category === 'Accident') && item.category === category);
      setTypeList(typeData);
      setType(0);
    } else {
      setTypeList(null);
    }
    if (category === 'Service') {
      setServiceShow(true);
      setIssueShow(false);
      setSelectedVehicle([]);
      getVehicless();
    } else if (category === 'Issue') {
      setServiceShow(false);
      setIssueShow(true);
      setCustomerLists(null);
      Getcustomer.dealerId = 0;
      
      //Customer Login Payload
      let CustomerPayload = {
        resellerId: resellerId, // Set Login Customer ID
        value: "fleet",
        dealerId: "0",
        customerId: orgId,
        searchText: "",
      };
      if (orgType === 3) {
        CommonPayload.value = "fleet";
        CommonPayload.dealerId = "0";
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
        CommonPayload.searchText = "";
        getFleetList(CommonPayload);
        if (allOption && selectedVehicle === null && orgType !== 3) {
          setFleet("-1");
          setVehicle("-1");
          setfleetList(null);
          setVehicleList([]);
        }
      } else {
        Getcustomer.value = 'customer';
        Getcustomer.customerId = value;
        const customerList = await getCustomerList(Getcustomer);
        setCustomerLists(customerList.data.data.content);
      }
      
    } else if (category === 'Vehicle Breakdown' || category === 'Charger Breakdown' || category === 'General Complaint' || category === 'Scheduled Service' || category === 'Telematics Issue' || category === 'Accident') {
      if(category==="Accident"){
        setCloseType(true);
      }else{
        setCloseType(false);
      }
      setServiceShow(false);
      setIssueShow(true);
      setCustomerLists(null);
      Getcustomer.dealerId = 0;
      //Customer Login Payload
      let CustomerPayload = {
        resellerId: resellerId, // Set Login Customer ID
        value: "fleet",
        dealerId: "0",
        customerId: orgId,
        searchText: "",
      };
      if (orgType === 3) {
      CommonPayload.value = "fleet";
        CommonPayload.dealerId = "0";
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
        CommonPayload.searchText = "";
        getFleetList(CommonPayload);
        if (allOption && selectedVehicle === null && orgType !== 3) {
          setFleet("-1");
          setVehicle("-1");
          setfleetList(null);
          setVehicleList([]);
        }

      } else {
        Getcustomer.value = 'customer';
        Getcustomer.customerId = value;
        //const customerList = await getCustomerList(Getcustomer);
        //setCustomerLists(customerList.data.data.content);
      }

    } else {
      setIssueShow(false);
      setServiceShow(false);
    }

    //filter category based set type list
    //setSkip(false);
    
  }, [category,categoryListData])

  //getFleet
  const getFleet = (customerId) => {
    setCustomer(customerId);
    if (searchText.name === 'Customer' && search) {
      CustomerPayload.value = "customer";
      CustomerPayload.dealerId = dealer;
      CustomerPayload.customerId = null;
      getCustomerList(CustomerPayload);
    }

    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      if (orgType === 3) {
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
      } else {
        CommonPayload.customerId = customerId;
      }
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
      if (allOption && selectedVehicle === null && orgType !== 3) {
        setFleet("-1");
        setVehicle("-1");
        setfleetList(null);
        setVehicleList([]);
      }
    } else {
      setFleet("-1");
      setVehicle("-1");
      setfleetList(null);
      setVehicleList([]);
      setSelectedVehicle(null);
    }
  };

  //Set default value here
  useEffect(() => {
    if (categorySuccess) {
      setStatus(categoryListData?.data?.status[0]?.value);
    }
  }, [categorySuccess])

  let Vehiclepayload = {
    "orgId": resellerId,
    "orgType": 2,
    "roleId": 6,
    "id": userId,
    "limit": 50,
    'searchText': '',
  }


  //service search
  const getSearchVehicleService = async (event, value) => {
    Vehiclepayload.searchText = value;
    const vehicles = await getVehicleLst(Vehiclepayload);
    if (vehicles?.data !== null) {
      setVehicleList(vehicles?.data?.data);
    } else {
      setVehicleList([]);
    }
  }


  //getVehicle for service
  const getVehicless = async () => {
    if (category === "Service") {
      const vehicles = await getVehicleLst(Vehiclepayload);
      if (vehicles?.data !== null) {
        setVehicleList(vehicles?.data?.data);
      } else {
        setVehicleList([]);
      }
    } else {
      setVehicleList(null);
      const vehicles = await getVehicleList(Vehiclepayload);
      if (vehicles?.data !== null) {
        setVehicleList(vehicles.data);
      } else {
        setVehicleList([]);
      }
    }
  }

  //Image Upload
  const appendImageName = (e) => {
    if (upload.length < 5) {
      const uploaded = [...upload];
      if (uploaded.findIndex((f) => f.name === e.target.files[0].name) === -1) {
        uploaded.push(e.target.files[0]);
      }
      setUpload(uploaded);
    }
  };

  const deleteImageName = (e, imagename) => {
    const remainImages = upload.filter((item, index) => item.name !== imagename);
    setUpload(remainImages);
  };




  //Vehicle Convert
  const convertToString = async () => {
    selectedVehicle !== null && selectedVehicle.forEach((key, index) => {
      vehicleNo.push(key.vehicleNo);
      vehicleId.push(key.vehicleId);
      vehicleChassisNo.push(key.chassisNo);
    })
  }


  //Save Payload
  let createPayload = {
    orgId: orgId,
    id: userId,
    orgType: orgType,
    createdBy: userId,
    subOrgType: 0,
  }

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  //Save Ticket
  const handleSubmit = async (event) => {
    const { target } = event;
    let imgData = {}
    imgData = Object.fromEntries(new FormData(target));
    //Add
    createPayload.categoryval = category;
    createPayload.typeval = type;
    createPayload.statusval = status;
    createPayload.servityval = servity;
    createPayload.title = title;
    createPayload.file = {};
    createPayload.vehicleNo = "";
    createPayload.chassisNo = "";
    var checkValue=true;
    var checkValueSec=true;
    if (category === "Service") {
      convertToString();
      createPayload.vehicleNo = vehicleNo.toString();
      createPayload.chassisNo = vehicleChassisNo.toString();
      createPayload.vehicleId = category !== 'Issue' ? (vehicleId.toString() === "" ? ((category === 'Service' || category === 'Feature Request') ? vehicleId.toString() : vehicle) : vehicleId.toString()) : vehicle;
      createPayload.vehicleOrgId = createPayload.vehicleId;
    } else {

      let result = Array.isArray(selectedVeh !== null ? selectedVeh?.data : "");
      if (result === false) {
        if(createPayload.vehicleId!==undefined && createPayload.vehicleId!=="0" && createPayload.vehicleId!=="-1"){
          createPayload.vehicleNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.vehicleNo?.toString() : "";
          createPayload.chassisNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.chassisNo?.toString() : "";
        }else{
          createPayload.vehicleNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.vehicleNo?.toString() : "";
          createPayload.chassisNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.chassisNo?.toString() : "";
          createPayload.vehicleNo=createPayload.vehicleNo.trim();
          createPayload.chassisNo=createPayload.chassisNo.trim();
        }
      } else {
        if(createPayload.vehicleId!==undefined && createPayload.vehicleId!=="0" && createPayload.vehicleId!=="-1"){
          createPayload.vehicleNo = selectedVeh !== null ? selectedVeh?.data[0]?.vehicleNo?.toString() : "";
          createPayload.chassisNo = selectedVeh !== null ? selectedVeh?.data[0]?.chassisNo?.toString() : "";
        }
      }
      createPayload.vehicleId = category !== 'Issue' ? (vehicleId.toString() === "" ? ((category === 'Service' || category === 'Feature Request') ? vehicleId.toString() : vehicle) : vehicleId.toString()) : vehicle;
      createPayload.vehicleId = createPayload.vehicleId === "-1" ? "0" : createPayload.vehicleId;
      createPayload.vehicleOrgId = parseInt(createPayload.vehicleId);      
    }

    if(orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1){
      if(region==="-1" || dealer==="-1"){
        if(category==="Vehicle Breakdown" || category==="Charger Breakdown" || category==="General Complaint" || category==="Scheduled Service" || category==="Accident"){
          checkValueSec=false;
        }
      }      
    }

    if(category!=="Telematics Issue" ){
      if(createPayload.vehicleId===undefined || createPayload.vehicleId==="-1" || createPayload.vehicleId==="0"){
        if(category==="Vehicle Breakdown" || category==="Charger Breakdown" || category==="General Complaint" || category==="Scheduled Service" || category==="Accident"){
          checkValue=false;
        }        
      }      
    }
     if(checkValue===true){
       if(checkValueSec===true){
        createPayload.description = editorValue;
        createPayload.fleetOrgId = fleet === "-1" ? 0 : parseInt(fleet);
        createPayload.customerOrgId = customer === "-1" ? 0 : parseInt(customer);
        createPayload.dealerOrgId = dealer === "-1" ? 0 : parseInt(dealer);
        createPayload.regionId = region === "-1" ? 0 : parseInt(region);
        createPayload.incidentDateTime = moment(incidentDateTime).format("yyyy-MM-DD HH:mm:ss");
        createPayload.incidentLocation = incidentLocation;
        createPayload.driverNumber = driverNumber;
        let formData = new FormData();
        formData.append(`finaldata`, JSON.stringify(createPayload));
        if (upload.length > 0) {
          for (let i = 0; i < upload.length; i++) {
            formData.append(`files`, upload[i]);
          }
        } else {
          formData.append(`files`, "");
        }    
          const responseData = await createTicketNew(formData);
          if (responseData?.data?.resultCode === 0) {
            setUpload([]);
            dispatch(snackService([responseData.data?.resultMessage, responseData?.data?.resultCode, true]));
           navigate(location.pathname);
          } else {
            dispatch(snackService([responseData.data?.resultMessage, responseData.data?.resultCode, true]));
          }
       }else{
         dispatch(snackService(["Please select Region,Dealer before submit", 1, true]));
       }      
       }else{
         dispatch(snackService(["Please select Vehicle before submit", 1, true]));
      }    
  }


  const Validation = (event) => {
    event.preventDefault();
    if (title === null || title === '') {
      dispatch(snackService(['Title Required', 1, true]));
    } else if (editorValue === null || editorValue === '') {
      dispatch(snackService(['Description Required', 1, true]));
    } else {
      handleSubmit(event);
    }
  }

  /*
  //Vehicle Search
  const vehicleSearch = async (event, vehicleVal) => {
      Vehiclepayload.searchText = vehicleVal;
      const vehicles = await getVehicleList(Vehiclepayload);
      if(vehicles.data !== null){
          setVehicleList(vehicles.data);
      }else{
          setVehicleList([]);
      }
  }
*/
  //-----------------------------------------------------------------------------------------------------------------
  // vehicleSearch

  const vehicleSearch = useCallback((searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: orgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  }, [search]);


  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles?.data);
      }else{
        setVehicleList([]);
      }
    }
  }, [searchVehicleSuccess]);

  useLayoutEffect(() => {
    if (vehicleSuccess) {

      setVehicleList(vehicledata?.data);
      /*
      if (location.pathname === "/geozone") {
        getFormData();
      }
      */

      if (search) {
        setSearch(false);
      }

      if (selectedVehicle !== null) {
        setVehicle(selectedVehicle[0]?.key);
        setVehicleList(vehicledata?.data);
      }

      if (
        !allOption &&
        selectedVehicle === null
        //&& location.pathname !== "/geozone"
      ) {
        selectVehicle(vehicledata?.data?.content[0]?.key);
        setLoad(true);
      }
    }
    if (
      allOption &&
      selectedVehicle === null
      //&&  location.pathname !== "/geozone"
    ) {
      setVehicle("-1");
      if (location.hash !== "#montraDaywise" && orgType !== 3) {
        setLoad(true);
      }
    }
  }, [vehicleSuccess]);

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);

    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  //resetTicket
  const resetTicket = () => {
    setEditorValue('');
    setCategory(resellerId === 110224 ? 'Vehicle Breakdown' : 'Service');
    setType(0);
    setStatus('Open');
    setServity('Medium');
    setTitle('');
    setSelectedVehicle(null);
    setVehicle("-1");
    setCustomer("-1");
    setFleet("-1");
    setDealer("-1");
    setRegion("-1");
    setTypeList(null);
    setUpload([]);
    //setSelectedVehicle([]);
  }


  //Get Region List
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();

  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getCustomer(dealerdata?.data.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (resellerId === 109951 && orgType !== 3 && orgType !== 5 && region === "1") {
        //KG 2W
        //getCustomer(113749);
        getCustomer("-1");
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && region === "1") {
        //getCustomer(dealerdata?.data.content[0]?.key);
        getCustomer("-1");
      } else if (!search) {
        setDealer("-1");
      }
    }
  }, [dealerSuccess]);

  useLayoutEffect(() => {
    if (customerSuccess) {
      setCustomerList(customerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getFleet(customerdata?.data?.content[0]?.key);
        let routeListPayload = {
          orgId: customerdata?.data?.content[0]?.key,
          page: 0,
          size: 25,
        };
        var custId = customerdata?.data?.content[0]?.key;
        if (custId !== null && custId !== "-1") {
          //getRouteList(routeListPayload);
        }
      } else if (search && selectedVehicle !== null) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (resellerId === 109951 && orgType !== 3 && orgType !== 5) {
        //KG 2W
        getFleet(113754);
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && region === "1") {
        getFleet(customerdata?.data?.content[0]?.key);
      }
      else if (!search) {
        setCustomer("-1");
      }
    }
  }, [customerSuccess]);


  useLayoutEffect(() => {
    if (fleetSuccess) {
      setfleetList(fleetdata?.data);
      if (!allOption && selectedVehicle === null) {
        getVehicle(fleetdata?.data?.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getVehicle(selectedVehicle[0]?.fleetId);
      }
      if (orgType === 3 && selectedVehicle === null) {
        getVehicle(fleetdata?.data?.content[0]?.key);
        setFleet(fleetdata?.data?.content[0]?.key);
      }
    }
    if (allOption && selectedVehicle === null && !search && orgType !== 3) {
      setFleet("-1");
    }
  }, [fleetSuccess]);

  //getDealer
  const getDealer = (regionId) => {
    if (searchText.name === 'Region') {
      getRegionList(regionPayload);
      setSearchText({ name: null, value: null });
    }
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setDealer("-1");
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setDealerList(null);
        setCustomerList(null);
        setfleetList(null);
        setVehicleList(null);
      }
    } else {
      setDealer("-1");
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setDealerList(null);
      setCustomerList(null);
      setfleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };


  //search
  const dropDownSearch = (searchText, name) => {
    
    setSearchText({ value: searchText, name: name });
    setSearch(true);
    if (name === "Vehicle") {
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    }

  };
  const getInnerText = (value, label) => {
    /*
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }else if(label === "Status"){
      setStatusName(value);
    }else if(label === "Severity"){
      setSeverityName(value);
    }
    */
  }

  //Dealor Login Payload
  let DealorPayload = {
    resellerId: resellerId, // Set Login Dealer ID
    value: "customer",
    dealerId: orgId,
    searchText: "",
  };

  //Fleet Payload
  let FleetPayload = {
    value: "orgId",
    paramValue: "113849", // Set Login Fleet ID
    searchText: "",
  };

  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //getCustomer
  const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if (searchText.name === 'Dealer' && search) {
      DealorPayload.dealerId = '0';
      DealorPayload.value = 'dealer';
      DealorPayload.regionId = region;
      getDealerList(DealorPayload);
      setSearchText({ name: null, value: null });
    }
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = dealerId;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setCustomerList(null);
        setfleetList(null);
        setVehicleList(null);
      }
    } else {
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setCustomerList(null);
      setfleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };

  useEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
      if (!allOption && selectedVehicle === null) {
        getDealer(regionData?.data.content[0].key);
        setLoad(true);
      } else if (search && selectedVehicle !== null) {
        //getDealer(selectedVehicle[0].regionId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (userData.resellerId === 109951 && orgType !== 3 && orgType !== 5 && orgType !== 1) {
        //KG 2W
        getDealer("-1");
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && orgType !== 1) {
        getDealer("-1");
      }
      else if (!search) {
        setRegion("-1");
      }
    }

  }, [regionSuccess]);


  //Based Login Implemented
  const reportsOnload = () => {
    //Region List Loaded
    let regionPayload = {
      value: "region",
      searchText: "",
    };
    switch (orgType) {
      case 2:
        getRegionList(regionPayload);
        break;

      case 5:
        // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 3:
        getFleet(orgId);
        setCustomer(orgId);
        break;
      case 1:
        getVehicle(orgId);
        setFleet(orgId);

    }
  };

  useEffect(() => {
    reportsOnload();
  }, []);

  //Get Vehicle
  const getVehicle = (fleetId) => {
    setFleet(fleetId);
    if (searchText.name === 'Fleet' && search) {
      FleetPayload.value = "fleet";
      FleetPayload.customerId = customer;
      FleetPayload.fleetId = null;
      FleetPayload.dealerId = '0';
      getFleetList(FleetPayload);
    }

    if (location.pathname === "/geozone" && selectedVehicle === null) {
      setVehicle("-1");
    }
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if (search) {
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
    } else {
      setVehicle("-1");
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };



  //Select Vehicle
  const selectVehicle = async (vehicleId, data) => {
    
    let Vehiclepayload = {
      "orgId": resellerId,
      "orgType": 2,
      "roleId": 6,
      "id": userId,
      "limit": 50,
      'searchText': '',
    }
    const SelctedVehData = vehicleList?.content.filter(
      (item) => item.key === vehicleId
    );
    if(vehicleId==="-1"){
      Vehiclepayload.searchText = "";
      setSearchText({ value: null });
    }else{
      Vehiclepayload.searchText = SelctedVehData[0]?.value;
    }
    const vehicles = await getVehicleLst(Vehiclepayload);
    setSelectedVeh(vehicles);
    setVehicle(vehicleId);
    if (search && vehicleId !== "-1" && vehicleId !== undefined) {
      const SelctedVehicleData = searchVehicles?.data?.content.filter(
        (item) => item.key === vehicleId
      );
      if (!allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
      }
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setCustomer(SelctedVehicleData[0]?.customerId);
        setFleet(SelctedVehicleData[0]?.fleetId);
        setVehicle(SelctedVehicleData[0]?.key);
      }
      setSelectedVehicle(SelctedVehicleData);
      setSearchText({ value: null });
    }
    if (vehicleId === "-1" && selectedVehicle !== null && vehicleId !== undefined && !search) {
      setSelectedVehicle(null);
      getVehicle(fleet);
      setVehicle("-1");
    }
  };

  const handleDateChange = (newValue) => {
    if(moment.utc(moment(newValue, "yyyy/MM/DD HH:mm"))<=moment.utc(moment(new Date(), "yyyy/MM/DD HH:mm"))){
      setTempDate(newValue); 
      var data=moment(newValue).format("yyyy-MM-DD HH:mm:ss");
      setIncidentDateTime(data);
    }else{
      dispatch(snackService(['Incident time should be less then or equal to current time', 1, true]));
    }
  };
 
  const onKeyDown = (e) => {
        e.preventDefault();
    };

    console.log('vehicleList', vehicleList);
  return (
    <form component={'form'} onSubmit={Validation} enctype="multipart/form-data">
      <Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={12}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Ticket Title</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter ticket name' value={title} fullWidth onChange={(event) => setTitle(event.target.value)} />
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Category</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={category} onChange={(event) => setCategory(event.target.value)}>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>

          <Grid item lg={6}>
            <FormControl fullWidth style={{"display":closeType===true?"none":"block"}}>
              <StyledFormControlLabel>Type</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={type} onChange={(event) => setType(event.target.value)}>
                <MenuItem value={0} sx={{ fontWeight: 300 }}><em>Select</em></MenuItem>
                {
                  typeList !== undefined && typeList !== null &&
                  typeList?.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Status</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={status} onChange={(event) => setStatus(event.target.value)} disabled>
                <MenuItem value={0} sx={{ fontWeight: 300 }}><em>Select</em></MenuItem>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.status.map((item, index) => item.value === 'Open' && <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Severity</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={servity} onChange={(event) => setServity(event.target.value)}>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.servity.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          {/* Show Vehicle Start*/}
          {serviceShow && <Grid item lg={12}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Vehicle</StyledFormControlLabel>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={vehicleList !== null && vehicleList !== undefined ? vehicleList : []}
                getOptionLabel={(options) => options?.vehicleNo}
                // value={selectedVehicle}
                // onChange={ selectVehicle}
                value={selectedVehicle}
                onChange={(event, value) => setSelectedVehicle(value)}
                onInputChange={(event, value) => getSearchVehicleService(event, value)}
                renderInput={(params) => <TextField {...params} />}
                size='small'
                disableCloseOnSelect
                disableClearable
                multiple
              />
            </FormControl>
          </Grid>
          }
          {/* Show Vehicle Start*/}

          {/* Show Issue Start*/}
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Region</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => setCustomer(event.target.value)}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            categoryListData !== undefined &&
                            categoryListData.data.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Dealer</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => setCustomer(event.target.value)}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            categoryListData !== undefined &&
                            categoryListData.data.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                    <SingleDropdownHelpDesk
                      label={"Region"}
                      listItems={regionList}
                      handleChange={getDealer}
                      value={region}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                      getInnerText={getInnerText}
                    />

              </Grid>
              }
            </>
          )}
          {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Dealer"}
                      listItems={dealerList}
                      handleChange={getCustomer}
                      value={dealer}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Customer"}
                      listItems={customerList}
                      handleChange={getFleet}
                      value={customer}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Fleet"}
                      listItems={fleetList}
                      handleChange={getVehicle}
                      value={fleet}
                      isSearch={dropDownSearch}
                      showAll={orgType === 3 ? false : allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {(issueShow) && <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>
                {(
                  <SingleDropdownHelpDesk
                    label={"Vehicle"}
                    listItems={vehicleList}
                    onClick={() => setSearch(false)}
                    handleChange={selectVehicle}
                    value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
                    isSearch={dropDownSearch}
                    searchText={searchText}
                    showAll={
                      location.pathname === "/geozone" && vehicleList !== null
                        ? true
                        : allOption
                    }
                  />
                )}


              </StyledFormControlLabel>
            </FormControl>
          </Grid>
          }
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Customer</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => getDropdownLists(event.target.value, 'customer')}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            customerLists !== undefined &&
                            customerLists?.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            }
            {
                issueShow &&
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Fleet</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={fleet} onChange={(event) => getDropdownLists(event.target.value, 'fleet')}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            fleetList !== undefined &&
                            fleetList?.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            }
            {
                issueShow &&
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Vehicle</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={vehicle} onChange={(event) => setVehicle(event.target.value)}>
                            <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                            {
                                vehicleList !== undefined &&
                                vehicleList?.map((item, index) => <MenuItem value={item.vehicleId}>{item.title}</MenuItem>)
                            }
                       
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          {/* Show Issue Start*/}
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required >
                  Incident Time 
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat="dd/MM/yyyy hh:mm a"
                      //minDate={subDays(new Date(), 7)}
                      value={tempDate}
                      onChange={handleDateChange}                     
                        renderInput={(params) => (
                         <StyledTextField
                           fullWidth
                           size="small"
                           onKeyDown={onKeyDown}
                           {...params}
                           readonly
                         />
                       )}                   
                       />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
          </>
            }
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Incident Location</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter Incident Location' value={incidentLocation} fullWidth onChange={(event) => setIncidentLocation(event.target.value)} />
            </FormControl>
          </Grid>
          </>
          }  
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Driver Number</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter Driver Number' value={driverNumber} inputProps={{ maxLength: 15 }} fullWidth onChange={(event) => setDriverNumber(event.target.value)} />
            </FormControl>
          </Grid>
          </>
          }
          {/* Description */}
          <Grid item lg={12}>
            <StyledFormControlLabel required>Description</StyledFormControlLabel>
            <CKEditor
              editor={ClassicEditor}
              data={editorValue}
              onChange={(event, editor) => {
                const editorDator = editor.getData();
                setEditorValue(editorDator);
              }}
            />
          </Grid>

          {/* Start Image Upload */}
          <Grid item lg={12}>
            <FileUploadBox sx={{ mt: 1 }}>
              <Icon sx={{ fontSize: '55px', mb: 2 }} color='primary'><RiUploadCloud2Fill /></Icon>
              <Typography variant="body2" > Upload Attachment</Typography>
              <input
                id="myInput"
                className="fileupload"
                multiple
                type={"file"}
                name="file"
                onChange={appendImageName}
              />
            </FileUploadBox>
            <Box>
              <List sx={{ width: "100%" }} dense={true}>
                {upload.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          type="button"
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => deleteImageName(e, item.name)}
                        >
                          <AiOutlineDelete />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar variant="square">
                          <BsImageAlt />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.name}
                      //
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Grid>
          {

          }
        </Grid>
        <Stack direction={'row'} spacing={2} sx={{ mt: 2, width: "100%" }} justifyContent={'center'}>
          <SoftButton variant='contained' color='success' className='success' type='submit' disabled={createLoading} sx={{ cursor: createLoading ? 'wait' : 'pointer' }}>
            {
              createLoading ? <ScaleLoader height={12} color='#fff' /> : 'Save'
            }
          </SoftButton>
          <SoftButton variant='contained' color='error' className='error' onClick={resetTicket}>Reset</SoftButton>
        </Stack>
      </Box>
    </form>
  )
}

export default CreateTicket