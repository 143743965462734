import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { GoDotFill } from "react-icons/go";
import { useTheme } from "@mui/styles";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
    useAuditLogMutation,
    useGetUtilizationListMutation
} from "../../store/slice/ApiSlice";
import {
    CardHeader,
    CardContent,
    Typography,
    Tooltip,
    Icon,
    Stack,
    Chip,
} from "@mui/material";

import {
    Exportoptions,
    StyledCardShadow,
    StyledPageLayout,
    StyledTab,
    StyledTabBox,
    StyledTableTitle,
    StyledTabsGroup,
    StyledToggleButton,
    StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import StyledTooltip from "../shared/StyledTooltip";
import FilterDropdown from "../NewDropdown/FilterDropdown";

const UtilizationReport = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let orgType = userData.userOrgType;

    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedPeriod, setSelectedPeriod] = useState(1);
    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    };
    const [getUtilization, { data: utilization, isLoading, isSuccess }] =
        useGetUtilizationListMutation();

    const theme = useTheme();
    useEffect(async () => {
        auditPayload.message = "Reports > Utilization";
        auditPayload.action = "View";
        auditPayload.featureName = "Utilization";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
    }, []);

    const calculateMonthHeader = (month) => {
        const d = new Date();
        d.setDate(d.getDate() - 1);
        d.setMonth(d.getMonth() - month);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[d.getMonth()] + " " + d.getFullYear();
    };
    const calculateDayHeader = (day) => {
        const d = new Date();
        d.setDate(d.getDate() - day);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
    };

    const columns = useMemo(
        () => [
            {
                Header: "Vehicle Number",
                accessor: "vehicleNo",
                show: false,
            },
            {
                Header: "Customer",
                accessor: "customerName",
            },
            {
                Header: (
                    calculateMonthHeader(2)
                ),
                accessor:"preSecMonthdistTraveled",
                columns: [
                    {
                        Header: "Active Vehicle (%)",
                        accessor: "month3ActivePercent",
                    },
                    {
                        Header: "Target Distance",
                        accessor: "presecMonthTargetDist",
                    },
                    {
                        Header: "Total Distance",
                        accessor: "preSecMonthdistTraveled",
                    },
                    {
                        Header: "Utilization (%)",
                        accessor: "month3UtilizationPercent",
                    },
                    {
                        Header: "Active Utilization (%)",
                        accessor: "month3ActiveUtilPercent",
                    },
                ],
            },
            {
                Header: (
                    calculateMonthHeader(1)
                ),
                accessor:"preMonthdistTraveled",
                columns: [
                    {
                        Header: "Active Vehicle (%)",
                        accessor: "month2ActivePercent",
                    },
                    {
                        Header: "Target Distance",
                        accessor: "preMonthTargetDist",
                    },
                    {
                        Header: "Total Distance",
                        accessor: "preMonthdistTraveled",
                    },
                    {
                        Header: "Utilization (%)",
                        accessor: "month2UtilizationPercent",
                    },
                    {
                        Header: "Active Utilization (%)",
                        accessor: "month2ActiveUtilPercent",
                    },
                ],
            },
            {
                Header: (
                    calculateMonthHeader(0)
                ),
                accessor:"curMonthdistTraveled",
                columns: [
                    {
                        Header: "Active Vehicle (%)",
                        accessor: "month1ActivePercent",
                    },
                    {
                        Header: "Target Distance",
                        accessor: "curMonthTargetDist",
                    },
                    {
                        Header: "Total Distance",
                        accessor: "curMonthdistTraveled",
                    },
                    {
                        Header: "Utilization (%)",
                        accessor: "month1UtilizationPercent",
                    },
                    {
                        Header: "Active Utilization (%)",
                        accessor: "month1ActiveUtilPercent",
                    },
                ],
            },
            {
                Header: "Last 7 days",
                columns: [
                    {
                        Header: "Target Distance",
                        accessor: "day1TargetDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(7)
                        ),
                        accessor: "day1ActualDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(6)
                        ),
                        accessor: "day2ActualDist",
                    },
                    {
                        Header: (
                                calculateDayHeader(5)
                        ),
                        accessor: "day3ActualDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(4)
                        ),
                        accessor: "day4ActualDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(3)
                        ),
                        accessor: "day5ActualDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(2)
                        ),
                        accessor: "day6ActualDist",
                    },
                    {
                        Header: (
                            calculateDayHeader(1)
                        ),
                        accessor: "day7ActualDist",
                    },
                ],
            },

        ],
        []
    );

    let commonPayload = {};
    const onSubmitForm = (data) => {
      setNewPage(0);
      setRowsPerPage(25);
      commonPayload.reselId = resellerId;
      commonPayload.regionId = data.regionId;
      commonPayload.dealerId = data.dealerId;
      commonPayload.custId = data.customerId;
      commonPayload.orgId = data.fleetId;
      commonPayload.vehicleId = data.vehicleId;
      commonPayload.search = "";
      commonPayload.startDate = data.fromDate;
      commonPayload.endDate = data.toDate;
      commonPayload.orderDir = "desc";
      commonPayload.start = newPage;
      commonPayload.length = rowsPerPage;
      commonPayload.size = rowsPerPage;
      //Service Call
      setPayload(commonPayload);
      getUtilization(commonPayload);
  
      auditPayload.message = "Reports > Utilization > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
      auditPayload.action = "Submit";
      auditPayload.featureName = "Utilization";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    };

    const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "targetdistance/getUtilizationReportList.xls?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;
      window.open(url);

      auditPayload.message = "Reports > Utilization > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Utilization";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
        if (payload.search.length >= 3) {
            getUtilization(payload);
        } else if (payload.search.length === 0) {
            getUtilization(payload);
        }
      };
      //Page Change
      const pageChange = (newPage, rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        payload.size = rowsPerPage;
        if(totalElements < rowsPerPage * newPage){
          setNewPage(0)
          payload.page = 0;
          getUtilization(payload);
        }else{
          setNewPage(newPage);
          payload.page = newPage;
          getUtilization(payload);
        }
      };
    
    
    
      useEffect(() => {
        if (isSuccess) {
          setResponseData([]);
          if (utilization.resultData !== null) {
            setResponseData(utilization.resultData.content);
            setTotalElements(utilization.resultData.totalElements);
            setTotalPages(utilization.resultData.totalPages);
          }
        }
      }, [isSuccess]);

    return (
        <StyledPageLayout>
            <Breadcrumb
                mainDiv="Reports"
                subDiv="Utilization"
                includePopup={true}
            ></Breadcrumb>
            <Box>

                <FilterDropdown getData={onSubmitForm} status={false} showCalendar={false} />
                <StyledCardShadow sx={{ mt: 2 }}>
                    <CardContent sx={{ py: 1 }}>
                        <DataTable
                            columns={columns}
                            payload={payload}
                            exportWithAll={true}
                            includeSearchBox={true}
                            onChangeXcl={onChangeXcl}
                            handleChange={handleSearch}
                            data={responseData}
                            styles={{ maxWidth: "100vw" }}
                            numberOfStickyColumns={1}
                            page={newPage}
                            rowsPerPage={rowsPerPage}
                            callbackChangePage={pageChange}
                            totalElements={totalElements}
                            totalPages={totalPages}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </StyledCardShadow>
            </Box>
        </StyledPageLayout>
    );
}

export default UtilizationReport;