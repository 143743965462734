import { Avatar, Badge, Box, Divider, Fab, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import { VehicleStatusBar } from '../../theme/StyledEle'
import { MovingBike, OfflineBike, IdleBike, StopBike, ChargingBike,  MovingAuto, OfflineAuto, IdleAuto, StopAuto, ChargingAuto, MovingTruck, OfflineTruck, IdleTruck, StopTruck, ChargingTruck, MovingCar, StopCar, ChargingCar, OfflineCar, MovingScvm, OfflineScvm, IdleScvm, StopScvm, ChargingScvm, MovingTractorm, OfflineTractorm, IdleTractorm, StopTractorm, ChargingTractorm, AccidentTruck, BreakdownTruck, AllTrucks, AllScv, AllTracktors, AutoAll, PtoTractorm , TowTracktorm } from '../Constans';
import { useSelector } from 'react-redux';
import { PiCaretLeftBold } from 'react-icons/pi';
function CountBar(props) {
  const { data, showCategory, mapRefetch ,imgData} = props;
  const theme = useTheme();
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
  const [show, setShow] = useState(true);
  //get localstorage data

  const userData = JSON.parse(localStorage.getItem('userData'));
  let VehicleType =  userData.vehicleType;
  let [vehicle, setVehicle] = useState(null);

  const getVehicleType = () => {
    switch(VehicleType){
      case 'TwoWheeler':
        setVehicle(2);
      break;
      case 'ThreeWheeler':
        setVehicle(3);
      break;
      case 'Truck':
        setVehicle(4);
      break;
      case 'Car':
        setVehicle(5);
      break;
      case 'Scv':
        setVehicle(6);
      break;
      case 'Tractor':
        setVehicle(7);
      break;
      default:
        setVehicle(2);
    }
  }

  useEffect(() => {
    if(VehicleType){
      getVehicleType();
    }
  },[userData?.resellerId])

  

  const [status, setStatus] = useState('none');
  //handleStatusChange
  const handleStatusChange = (event, newStatus) => {
    setStatus(newStatus);
    props.closeTreeViewfn(newStatus);
    mapRefetch();
  }
  
  

  useEffect(() => {
    if(!showCategory){
      setStatus('none');
    }
  },[showCategory])


  return (
  //vehicleTrails === null &&  userData.resellerId !== 110224 &&
    <VehicleStatusBar sx={{right:show ? '0px' : '-70px'}}>
        <Paper elevation={0} className='vehicle-status-root' sx={{position:'relative'}}>
          
              {
                
                <Tabs value={status} onChange={handleStatusChange}>
                <Tab label={''} value={'none'} sx={{display:'none'}}/>
                <Tab label={'All'} value={''}  icon={<Badge
                  badgeContent={data?.data?.totalCount}
                  color='success'
                  variant='square'
                  max={999999}
                  showZero={true}
                >
                  <Avatar variant="square" 
                    id={data?.data?.totalCount}
                    src={
                      vehicle === 2 ? MovingBike :
                      vehicle === 3 ? AutoAll :
                      vehicle === 4 ? AllTrucks : 
                      vehicle === 5 ? MovingCar : 
                      vehicle === 6 ? AllScv :
                      vehicle === 7 ? AllTracktors : MovingBike
                      
                    }/>
                </Badge>}/>

                <Tab label={'Moving'} value={'Moving'} data-count={data?.data?.totalCount}  icon={<Badge
                    badgeContent={data?.data?.moveCount}
                    color='success'
                    variant='square'
                    max={999999}
                    showZero={true}
                  >
              <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingBike) :
                    vehicle === 3 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingAuto) :
                      vehicle === 4 ? (imgData?.moving !=="-" && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingTruck) :
                        vehicle === 5 ? (imgData?.moving  !=="-" && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingCar) :
                          vehicle === 6 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingScvm) :
                            vehicle === 7 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTractorm) :
                              ''
                }
              />

            </Badge>} />;

            {
                  userData.resellerId === 114663 &&   <Tab label={'PTO'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.ptoCount}
                    color='info'
                    max={999999}  
                    showZero={true}
                  >
                    <Avatar variant="square"
                      src={
                              vehicle === 7 ? (imgData?.pto !=="-"  && imgData?.pto !== null  ? `https://${imgData?.pto}` : PtoTractorm) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'PTO'}
                   />  
                }

                {
                  userData.resellerId === 114663 &&   <Tab label={'Idle'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.idleCount}
                    color='primary'
                    max={999999}  
                    showZero={true}
                  >
                    <Avatar variant="square"
                      src={
                              vehicle === 7 ? (imgData?.idle !=="-"  && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleTractorm) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Idle'}
                   />  
                }

                {
                  userData.resellerId === 114613 &&   <Tab label={'Idle'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.idleCount}
                    color='primary'
                    max={999999}  
                    showZero={true}
                  >
                    <Avatar variant="square"
                      src={
                              vehicle === 6 ? (imgData?.idle !=="-"  && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleScvm) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Idle'}
                   />  
                } 

                {
                  userData.resellerId === 110050 &&   <Tab label={'Idle'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.idleCount}
                    color='primary'
                    max={999999}  
                    showZero={true}
                  >
                    <Avatar variant="square"
                      src={
                              vehicle === 3 ? (imgData?.idle !=="-"  && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleAuto) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Idle'}
                   />  
                }

                <Tab label={'Stop'}  data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.stopCount}
                  color='error'
                  max={999999}
                  showZero={true}
                >
                <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.stop !=="-" && imgData?.stop !== null ? `https://${imgData?.stop}` : StopBike) :
                    vehicle === 3 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopAuto) :
                      vehicle === 4 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopTruck) :
                        vehicle === 5 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopCar) :
                          vehicle === 6 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopScvm) :
                            vehicle === 7 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopTractorm) :
                              ''
                }
              />
                </Badge>}
                value={'Stop'}/>

              <Tab label={'Charging'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.chargeCount}
                  color='warning'
                  max={999999}
                  showZero={true}
                >
                  <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingBike) :
                    vehicle === 3 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingAuto) :
                      vehicle === 4 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingTruck) :
                        vehicle === 5 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingCar) :
                          vehicle === 6 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingScvm) :
                            vehicle === 7 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingTractorm) :
                              ''
                }
              />
                </Badge>}
                 value={'Charging'}/>

                {
                  userData.resellerId === 114663 &&   <Tab label={'Towing'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.towingCount}
                    max={999999}  
                    showZero={true}
                    color='primary'
                    >
                    <Avatar variant="square"
                      src={
                              vehicle === 7 ? (imgData?.towing !=="-"  && imgData?.towing !== null  ? `https://${imgData?.towing}` : TowTracktorm) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Towing'}
                   />  
                } 

                <Tab label={'Offline'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.offlineCount}
                  color='default'
                  max={999999}
                  showZero={true}
                  sx={{'& .MuiBadge-badge':{background:theme.palette.grey[600], color:theme.palette.primary.contrastText}}}
                >
                  <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineBike) :
                    vehicle === 3 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineAuto) :
                      vehicle === 4 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineTruck) :
                        vehicle === 5 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineCar) :
                          vehicle === 6 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineScvm) :
                            vehicle === 7 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineTractorm) :
                              ''
                }
              />
                </Badge>}
                 value={'Offline'}/>
                {
                  userData.resellerId === 110224 &&   <Tab label={'Breakdown'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.breakdownCount}
                    color='info'
                    max={999999}
                    showZero={true}
                    sx={{'& .MuiBadge-badge':{background:'#928d4d !important', color:theme.palette.primary.contrastText}}}
                  >
                    <Avatar variant="square"
                      src={
                        vehicle === 2 ? (imgData?.breakdown !=="-" && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineBike) :
                          vehicle === 3 ? (imgData?.breakdown !=="-"  && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineAuto) :
                            vehicle === 4 ? (imgData?.breakdown !=="-"   && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : BreakdownTruck) :
                              vehicle === 5 ? (imgData?.breakdown !=="-"  && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineCar) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Breakdown'}/>
                }
               
                {
                  userData.resellerId === 110224 &&  <Tab label={'Accident'} data-count={data?.data?.totalCount} 
                  icon={<Badge
                    badgeContent={data?.data?.accidentCount}
                    color='info'
                    max={999999}
                    showZero={true}
                  >
                     <Avatar variant="square"
                      src={
                        vehicle === 2 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineBike) :
                          vehicle === 3 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineAuto) :
                            vehicle === 4 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : AccidentTruck) :
                              vehicle === 5 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineCar) :
                                ''
                      }
                    />
                  </Badge>}
                   value={'Accident'}/>
                
                }
                </Tabs>
              }
              

              {/* {
                  userData.resellerId !== 110224 && vehicleTrails === null &&
                  <Stack direction={'row'} spacing={3} sx={{pt:2}}>
                      <Box className='vehicle-status-box'>
                    <Badge
                      badgeContent={data?.data?.moveCount}
                      color='success'
                      variant='square'
                      max={999999}
                      showZero={true}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? ((imgData?.moving !=="-") && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingBike) :
                        vehicle === 3 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingAuto) :
                          vehicle === 4 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTruck) :
                            vehicle === 5 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingCar) :
                              vehicle === 6 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingScvm) :
                                vehicle === 7 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTractorm) :
                                  MovingBike
                    }
                  />
                    </Badge>
                    <Typography>Moving</Typography>
                </Box>
                <Box className='vehicle-status-box'>
                    <Badge
                      badgeContent={data?.data?.stopCount}
                      color='error'
                      max={999999}
                      showZero={true}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? ((imgData?.stop !=="-") && imgData?.stop !== null? `https://${imgData?.stop}` : StopBike) :
                        vehicle === 3 ? ((imgData?.stop !=="-") && imgData?.stop !== null ? `https://${imgData?.stop}` : StopAuto) :
                          vehicle === 4 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTruck) :
                            vehicle === 5 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopCar) :
                              vehicle === 6 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopScvm) :
                                vehicle === 7 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTractorm) :
                                  ''
                    }
                  />
                    </Badge>
                    <Typography>Stop</Typography>
                </Box>
                <Box className='vehicle-status-box'>
                    <Badge
                      badgeContent={data?.data?.chargeCount}
                      color='warning'
                      max={999999}
                      showZero={true}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingBike) :
                        vehicle === 3 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingAuto) :
                          vehicle === 4 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingTruck) :
                            vehicle === 5 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingCar) :
                              vehicle === 6 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingScvm) :
                                vehicle === 7 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingTractorm) :
                                  ''
                    }
                  />
                    </Badge>
                    <Typography>Charging</Typography>
                </Box>
                <Box className='vehicle-status-box'>
                    <Badge
                      badgeContent={data?.data?.offlineCount}
                      color='info'
                      max={999999}
                      showZero={true}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineBike) :
                        vehicle === 3 ? (imgData?.offline !=="-"  && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineAuto) :
                          vehicle === 4 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTruck) :
                            vehicle === 5 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineCar) :
                              vehicle === 6 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineScvm) :
                                vehicle === 7 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTractorm) :
                                  ''
                    }
                  />
                    </Badge>
                    <Typography>Offline</Typography>
                </Box>
            </Stack>
              } */}
            
        </Paper>
    </VehicleStatusBar>
  )
}

export default CountBar
