import { Box, CardContent, CardHeader, Dialog, DialogContent, Icon, Typography, Button } from "@mui/material";
import { SoftButton, StyledCardShadow, StyledFormButtonsRow, StyledTableTitle } from "../../theme/StyledEle";
import React, { useState, useMemo, useEffect } from "react";
import { DataTable } from "../shared/data_table/DataTable";
import { useTheme } from "@mui/styles";
import ReportServices from "../../services/reportServices";
import {
    useAuditLogMutation
} from "../../store/slice/ApiSlice";
import { useGetServiceDetailsListMutation, useDeleteServiceDetailsMutation } from "../../store/slice/ReportSlice/ServiceSlaSlice";
import { AiOutlineDelete } from "react-icons/ai";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";

const ServiceDetailsList = ({editServiceDetails,commonFilter}) => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let orgType = userData.userOrgType;

    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [auditLog] = useAuditLogMutation();
    const [payload, setPayload] = useState(null);
    const[deleteItem,setDeleteItem] = useState(null);
    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    };

    const [getServiceDetailsList, { data: serviceDetails, isLoading, isSuccess }] =
        useGetServiceDetailsListMutation();

    const theme = useTheme();
    useEffect(async () => {
        auditPayload.message = "Reports > Service SLA";
        auditPayload.action = "View";
        auditPayload.featureName = "Service SLA";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
        onSubmitForm();
    }, []);

    let commonPayload = {};
    const onSubmitForm = () => {
        setNewPage(0);
        setRowsPerPage(50);
        commonPayload.resellerId = resellerId;
        commonPayload.regionId = -1;
        commonPayload.dealerId = -1;
        commonPayload.custId = -1;
        commonPayload.orgId = -1;
        commonPayload.vehicleId = -1;
        commonPayload.search = "";

        commonPayload.orderDir = "desc";
        commonPayload.start = newPage;
        commonPayload.length = rowsPerPage;
        commonPayload.size = rowsPerPage;
        //Service Call
        setPayload(commonPayload);
        getServiceDetailsList(commonPayload);

        auditPayload.message = "Reports > Service SLA > " + " > " + "Submit";
        auditPayload.action = "Submit";
        auditPayload.featureName = "Service SLA";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = userName;
        auditLog(auditPayload);
    };

    const [
        delteItem,
        {
          data: deleteData,
          isLoading: isDeleteLoading,
          isSuccess: isDeleteSuccess,
        },
      ] = useDeleteServiceDetailsMutation();

    const [deleteie, setDeleteIe] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState('Please unassign the vehicles');
    //OnDelete call
    const onDelete = (itemDeleted) => {
      setDeleteItem(itemDeleted);
      setDeleteIe(true);
    };
  
    const deleteClose = () => {
      setDeleteIe(false);
    }

    
    const deleteItems = () => {
        let payload = {
          id: deleteItem.id,
        };
        delteItem(payload);
      };

    useEffect(() => {
        if (isDeleteSuccess) {
          setDeleteItem(null);
          dispatch(
            snackService([
            deleteData?.resultText ==="success"?"Service Center Deleted Sucessfully":"Failed to Delete Service Center",
            deleteData?.resultText ==="success"?"Success":"Failed",
              true,
            ])
          );
          getServiceDetailsList(payload);
          deleteClose();
        }
      }, [isDeleteSuccess]);


    const columns = useMemo(
        () => [
            {
                Header: "Dealer",
                accessor: "dealerName",
            },
            {
                Header: "Service Location",
                accessor: "showroomName",
                width: 120,
            },
            {
                Header: "Service Center Gps",
                accessor: "gpsLocation",
                width: 300,
                Cell: ({ row, column, cell }) => {
                    const refAddres = React.useRef(row);
                    var refLink = React.useRef(row);
                    var getAddressBtn = React.useRef(row);
                    var toggleAddressBtn = React.useRef(row);
                    const getAddress = async (e) => {
                        if (refAddres.current.innerText === "") {
                            var request = {};
                            request.latLng = e.target.value;
                            // rowid = e.target.id;
                            const responseData = (
                                await ReportServices.getGeocodeAddress(request)
                            ).data;
                            refAddres.current.innerText = responseData;
                            if (responseData !== "") {
                                refLink.current.style.display = "none";
                                refAddres.current.style.display = "block";
                                toggleAddressBtn.current.style.display = "block";
                                getAddressBtn.current.style.display = "none";
                                toggleAddressBtn.current.innerText = "LatLng";
                            }
                        }
                    };

                    //Address toggle
                    const ToggleAdderss = () => {
                        if (refAddres.current.style.display === "block") {
                            refAddres.current.style.display = "none";
                            refLink.current.style.display = "block";
                            toggleAddressBtn.current.innerText = "Address";
                        } else {
                            refAddres.current.style.display = "block";
                            refLink.current.style.display = "none";
                            toggleAddressBtn.current.innerText = "LatLng";
                        }
                    };
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                                className="addresslink"
                                id={row.original.startLoc}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Button
                                    onClick={(e) => getAddress(e)}
                                    value={row.original.gpsLocation}
                                    ref={getAddressBtn}
                                >
                                    Address
                                </Button>
                                <Button
                                    onClick={(e) => ToggleAdderss(e)}
                                    ref={toggleAddressBtn}
                                    sx={{ display: "none" }}
                                >
                                    LatLng
                                </Button>
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                                    target="_blank"
                                    className="latlink"
                                    ref={refLink}
                                    id={row.original.gpsLocation}
                                >
                                    {row.original.gpsLocation}
                                </a>
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                                    target="_blank"
                                    className="address"
                                    ref={refAddres}
                                    id={row.original.gpsLocation}
                                    key={row.original.gpsLocation}
                                ></a>
                            </Box>
                        </Box>
                    );
                },
            },
            {
                Header: "Service Center Radius",
                accessor: "showroomRadius",
            },
            {
                Header: "Action",
                accessor: "buttonEdit",
            }
        ],
        []
    );

    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(50);
        payload.page = 0;
        payload.rowsPerPage = 50;
        payload.start = 0;
        payload.length = 50;
        if (payload.search.length >= 3) {
            getServiceDetailsList(payload);
        } else if (payload.search.length === 0) {
            getServiceDetailsList(payload);
        }
    };
    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        payload.size = rowsPerPage;
        if (totalElements < rowsPerPage * newPage) {
            setNewPage(0)
            payload.page = 0;
            payload.start = 0;
            getServiceDetailsList(payload);
        } else {
            setNewPage(newPage);
            payload.page = newPage;
            payload.start = newPage;
            getServiceDetailsList(payload);
        }
    };

    const onEdit = (itemEdit) => {
        editServiceDetails(itemEdit);
      };

    useEffect(() => {
        if (isSuccess) {
            setResponseData([]);
            if (serviceDetails.data !== null) {
                const contentData = serviceDetails.data.content.map((e) => ({
                    ...e,
                    buttonEdit: ["edit", "delete"]
                }));
                setResponseData(contentData);
                setTotalElements(serviceDetails.data.totalElements);
                setTotalPages(serviceDetails.data.totalPages);
            }
        }
    }, [isSuccess]);
    return (
        <>
            <StyledCardShadow sx={{ mt: 2 }}>
                <CardContent sx={{ py: 1 }}>
                    <DataTable
                        columns={columns}
                        payload={payload}
                        exportWithAll={true}
                        includeSearchBox={true}
                        // onChangeXcl={onChangeXcl}
                        handleChange={handleSearch}
                        data={responseData}
                        styles={{ maxWidth: "100vw" }}
                        numberOfStickyColumns={1}
                        page={newPage}
                        rowsPerPage={rowsPerPage}
                        callbackChangePage={pageChange}
                        totalElements={totalElements}
                        totalPages={totalPages}
                        isLoading={isLoading}
                        commonFilter={commonFilter}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        includeExportButton={false}
                    />
                </CardContent>
            </StyledCardShadow>
            <Dialog open={deleteie} onClose={deleteClose} maxWidth={"sm"} fullWidth={true}>

                <DialogContent sx={{ px: 0, py: 2 }}>
                    {

                        <>
                            <Icon
                                sx={{
                                    fontSize: "50px",
                                    height: "auto",
                                    margin: "auto",
                                    display: "flex",
                                }}
                                color="error"
                            >
                                <AiOutlineDelete />
                            </Icon>
                            <Typography sx={{ textAlign: "center" }} variant="h6">
                                Are you sure want to delete?
                            </Typography>
                            <Box sx={{ px: 2 }}>
                                <Typography sx={{ textAlign: "center", my: 2 }}>
                                    Serice Center Name: {deleteItem?.showroomName}
                                </Typography>
                            </Box>
                            <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
                                <SoftButton
                                    size="small"
                                    color="error"
                                    className="error"
                                    onClick={deleteClose}
                                >
                                    Cancel
                                </SoftButton>
                                <SoftButton
                                    size="small"
                                    className="success"
                                    color="success"
                                    type="submit"
                                    onClick={deleteItems}
                                >
                                    Confirm
                                </SoftButton>
                            </StyledFormButtonsRow>
                        </>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ServiceDetailsList;