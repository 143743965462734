import React, { useEffect, useRef, useState } from 'react'
import { RouteListbar, StyledSwitch,CategoryListbar, StyledIconButton } from '../../../theme/StyledEle'
import { Checkbox, Fab, Icon, List, ListItem, ListItemIcon, ListItemText, ToggleButton, Paper, Stack, Tooltip, Typography, Divider,CardHeader, CardContent,Grid,Card,useTheme,IconButton,InputBase, Box, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { ListItemButton,alpha } from '@mui/material';
import { useGetEditRouteTripMutation, useGetRouteListForMapMutation } from '../../../store/slice/RouteTripSlice/RouteTripSlice';
import { routeTrips,selectedVehicleData,searchCategoryKeyWord } from '../../../store/slice/ControlsSlice';
import { PiCaretLeftBold } from 'react-icons/pi';
import { FaFileCsv, FaRoute } from "react-icons/fa6";
import Scrollbars from "react-custom-scrollbars-2";
import { HiOutlineCog } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RiCloseLine } from 'react-icons/ri';
import StatusCard from './StatusCard';
import { Empty } from 'antd';
import { useChargingMutation, useStopPageMutation } from '../../../store/slice/ApiSlice';
import moment from 'moment';

function CategoryBar(props) {
    const { showTrails, handleTrails, showCatgryVehicleStatus, vehicleSummaryWindowfn, getCategorySearch, statusPayload, vehicleCount } = props;
    const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
    const vehicleCategoryList = useSelector((state) => state.controls.vehicleCategoryList);
    //search keyword
    const isSearchKeyword = useSelector(
        (state) => state.controls.treeview.searchCategoryKeyWord
    );
 
    const [subCategoryBtnShow,setSubCategoryBtnShow] =useState("");
    const theme = useTheme();
    const [searchKey, setSearchKey] = useState(null);
    //Route call
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let orgType = userData.userOrgType;

    const date = useSelector((state) => state.controls.treeview.date);
    const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);

    const [ getrouteList, {data:routeList, isLoading:routeListLoading, isSuccess:routeListSuccess}] = useGetRouteListForMapMutation();
    const [getCharging, {data:chargingData, isLoading:chargingLoading, isSuccess:chargingSuccess}] = useChargingMutation();
    const [getStoppage, {data:stoppageData, isLoading:stoppageLoading, isSuccess:stoppageSuccess}] = useStopPageMutation();

    const dispatch = useDispatch();
    const GetRouetList = async () => {
        let getRoutePayload = {
            'vehicleId': selectedVehicle
        }
        const RouteTrips = await getrouteList(getRoutePayload);
    }

    //get charging and Stoppage

    const getCharingStop = (vehicleId) => {
        let chargingPayload = {
            vehicleId:vehicleId,
            startDate:date[0],
            endDate:date[1],
            //orgId:fleetItem.orgId,
            page:0,
            size:100,
            "reselId": 0,
            "regionId": "0",
            "dealerId": "0",
            "custId": "0",
            "orgId": "-0",
            "search": "",
            "orderDir": "desc",
          }
        //   getCharging(chargingPayload);
        //   getStoppage(chargingPayload);
    }



    const showViewDetails =(vehicleId,orgId)=>{
        dispatch(selectedVehicleData(null));       
        props.vehicleSummaryfn(vehicleId, orgId);
        dispatch(selectedVehicleData(vehicleId));
        getCharingStop(vehicleId);
    }

    useEffect(() => {
        if(selectedVehicle !== null && selectedVehicle !== undefined){
            GetRouetList();
        }
    },[selectedVehicle]);

    const [ getSingleRouteTrip ] = useGetEditRouteTripMutation();
    const [routeId, setRouteId] = useState(null);
    const getRouteSelect = (id) => {
        if(id === routeId){
            setRouteId(null);
            dispatch(routeTrips(null));
        }else{
            setRouteId(id);
            getRouteTrips(id);
        }
    }

   
    const searchVehicle=(event)=>{
        setSearchKey(event.target.value);
        //dispatch(searchCategoryKeyWord(event.target.value));
        getCategorySearch(event.target.value)
    }    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    useEffect(() => {
        setRouteId(null); // Change date or Trails time reset check box
    },[date, trailsTime, selectedVehicle])
    
    const getRouteTrips = async (id) => {
        const singleRouteData = await getSingleRouteTrip({'routeId': id});
        dispatch(routeTrips(singleRouteData.data.data));
    }
    const [show, setShow] = useState(true);
    
    const getStatusColor=(showCatgryVehicleStatus)=>{
        let color=theme.palette.primary.main;
        switch(showCatgryVehicleStatus){
            case "Offline":
                color=theme.palette.grey[700];
                break;
            case "Charging":
                color=theme.palette.warning.main;
                break;
            case "Stop":
                color=theme.palette.error.main;
                break;
            case "Moving":
                color=theme.palette.success.main;
                break;
            case "Breakdown":
                color="#7c793a";
                break;
            case "Accident":
                color=theme.palette.info.main;
                break;
            case "PTO":
                color="#1fb2cc";
                break;    
            case "Idle":
                color=theme.palette.primary.main;
            break;  
            case "Towing":
                color="#9130B2";
            break; 
            default:
                color=theme.palette.primary.main; 
        }
      return color;
    }

    const statusRef = useRef();
    //scroll to top
    
    useEffect(() => {
        if(statusRef.current !== null){
            statusRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    },[vehicleCategoryList])


    //Download CSV based on satus
    
    const onChangeXcl = () => {
        let customerId = orgType === 3 ? orgId : 0;
        let dealerId = orgType === 5 ? orgId : 0;
        try{
            var url = 
            process.env.REACT_APP_API_BASE_URL +
            "mapView/getCategoryVehicleListXLS.csv?" +
            "roleId=" + 6  +
            "&sessionOrgId=" + 110224 +
            "&logoName=" + 'string' +
            "&vehicleId=" + 0 +
            "&strDate=" + moment().format('YYYY-MM-DD 00:00:00') +
            "&endDate=" + moment().format('YYYY-MM-DD 23:59:59') +
            "&statusFlag=" + 5 +
            "&orgId=" + orgId +
            "&mins=" + 1 +
            "&orgType=" + orgType  +
            "&agencyId=" + 0 +
            "&enLatLng=" + 0 +
            "&sessionOrgVehicleDisplay=" + 1 +
            "&startOdometer=" + 0 +
            "&endOdometer=" + 0 +
            "&storepack=" + 0 +
            "&hoursFeed=" + 0 +
            "&userId=" + 2 +
            "&viewTrails30se=" + 0 +
            "&regionId=" + 0 +
            "&dealerId=" + dealerId +
            "&resellerId=" + resellerId +
            "&customerId=" + customerId +
            "&fleetOrgId=" + 0 +
            "&start=" + statusPayload.start +
            "&length=" + statusPayload.length +
            "&search=" + '' +
            "&vehmodel=" + 'string' +
            "&categoryType=" + statusPayload.categoryType +
            "&cngVehicle=" + true ;
            window.open(url);
            window.focus();
        }catch (error){
            console.warn(error);
        }
    }

   return (
    <CategoryListbar sx={{right:show ? '0px' : '-280px', diplay:show ? 'block' : 'none'}} >
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
            <Box
                sx={{
                    background: getStatusColor(showCatgryVehicleStatus)
                    // showCatgryVehicleStatus === 'Stop' ? alpha(theme.palette.error.main, .8) :
                    // showCatgryVehicleStatus === 'Moving' ? theme.palette.success.main :
                    // showCatgryVehicleStatus === 'Offline' ? theme.palette.grey[600] :
                    // showCatgryVehicleStatus === 'Charging' ? theme.palette.warning.main: theme.palette.primary.main
                    // showCatgryVehicleStatus === 'Charging' ? theme.palette.warning.main: theme.palette.primary.main
                    , 
                    width:'100%', 
                    padding:'5px 10px'
                }}
            >
                
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography color={'common.white'}>
                        {showCatgryVehicleStatus !== '' ? showCatgryVehicleStatus : 'All'} 
                          ( {showCatgryVehicleStatus === '' ? vehicleCount?.data?.totalCount :
                         showCatgryVehicleStatus === 'Moving' ? vehicleCount?.data?.moveCount :
                         showCatgryVehicleStatus === 'Charging' ?  vehicleCount?.data?.chargeCount : 
                         showCatgryVehicleStatus === 'Offline' ?  vehicleCount?.data?.offlineCount : 
                         showCatgryVehicleStatus === 'Stop' ?  vehicleCount?.data?.stopCount : 
                         showCatgryVehicleStatus === 'Breakdown' ?  vehicleCount?.data?.breakdownCount : 
                         showCatgryVehicleStatus === 'Accident' ?  vehicleCount?.data?.accidentCount : 
                         showCatgryVehicleStatus === 'PTO' ?  vehicleCount?.data?.ptoCount :
                         showCatgryVehicleStatus === 'Idle' ?  vehicleCount?.data?.idleCount :
                         showCatgryVehicleStatus === 'Towing' ?  vehicleCount?.data?.towingCount : ''
                        } )</Typography>
                    <StyledIconButton  onClick={()=>{props.closeCategoryfn()}} size='small' sx={{color:'#fff'}}>
                        <RiCloseLine />
                    </StyledIconButton>
                </Stack>
                
            </Box>
        </Stack>

        <Paper elevation={0} sx={{display:'flex', padding:'0px 10px', alignItems:'center'}}>
            <Icon sx={{display:'flex'}} color={'text.disabled'}>
                <CiSearch />
            </Icon>
          <InputBase
            placeholder="Search vehicle"
            sx={{ width: "100%", padding:'5px 5px'}}    
            value={searchKey}
            onChange={(event) => searchVehicle(event)}
          />
         {
            vehicleCategoryList?.result.length > 0 &&
            <Tooltip title={'Export'} arrow={true}>
            <IconButton color='success' size='small' onClick={() => onChangeXcl()}>
                <FaFileCsv />
            </IconButton>
            </Tooltip>
}          
         

      </Paper>
    
    <Box sx={{overflowY:'auto', height:'calc(100% - 15%)', padding:'10px 5px'}} ref={statusRef}>

    { vehicleCategoryList?.result.length > 0 ? vehicleCategoryList?.result?.map((category, index)=>(
        <StatusCard
            key={index}
            data={category}
            showSummary={showViewDetails}
            selectedVehicle={selectedVehicle}
            vehicleSummary={vehicleSummaryWindowfn}
         />)) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Vehicle not found'}/>}
    </Box>

     {/* <Scrollbars autoHeight
                  autoHeightMin={0}
                  autoHeightMax={500}>     
              
        {/* <Tooltip title={showTrails ? 'Hide trails' : 'Show Trails'} arrow>
                <StyledSwitch
                    onChange={(event) => handleTrails(!showTrails)}
                    checked={showTrails}
                />
            </Tooltip>         
        
    </Scrollbars> */}
    </CategoryListbar>
  )
}

export default CategoryBar