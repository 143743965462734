import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { useTheme } from "@mui/styles";
import { Breadcrumb } from "../shared/Breadcrumbs";
import moment from "moment";
import {
    useAuditLogMutation,
    useGetVehicleEfficiencyListMutation
} from "../../store/slice/ApiSlice";
import {
    CardContent,
} from "@mui/material";
import {
    StyledCardShadow,
    StyledPageLayout,
} from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";

const VehicleEfficiencyReport = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let orgType = userData.userOrgType;
    let lastMonthColumnName="";
    let previousMonthColumnName="";
    const [payload, setPayload] = useState(null);
    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedPeriod, setSelectedPeriod] = useState(1);
    const [auditLog] = useAuditLogMutation();
    const columns = useMemo(
        () => [
            {
                Header: "Vehicle Number",
                accessor: "vehicleNo",
                show: false,
            },
            {
                Header: "Month",
                accessor: "currentMonth",
            },
            {
                Header: "Distance Travelled (km)",
                accessor: "distanceTravelled",
            },
            {
                Header: "Energy Consumed (wh)",
                accessor: "energyConsumed",
            },
            {
                Header: "Efficiency (wh/km)",
                accessor: "vehicleEfficency",
            },
            {
                Header: "Ride Mode Usage",
                accessor:"",
                columns: [
                    {
                        Header: "ECO Mode (%)",
                        accessor: "ecoMode",
                    },
                    {
                        Header: "Drive Mode (%)",
                        accessor: "driveMode",
                    },
                    {
                        Header: "Ride Mode (%)",
                        accessor: "rideMode",
                    },
                ],
            },
            {
                Header: "Speed bins",
                accessor:"",
                columns: [
                    {
                        Header: "0-10km/h (%)",
                        accessor: "speedBelow10",
                    },
                    {
                        Header: "10-25km/h (%)",
                        accessor: "speedBelow25",
                    },
                    {
                        Header: "25-40km/h (%)",
                        accessor: "speedBelow40",
                    },
                    {
                        Header: ">40km/h (%)",
                        accessor: "speedAbove40",
                    },
                ],
            },
            {
                Header: "Last Month Efficiency",
                accessor: "lastMonth1Efficiency",
            },{
                Header: "Two Month ago Efficiency",
                accessor: "lastMonth2Efficiency",
            } 
    
        ],
        []
    );
    
    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    };
    const [getEfficiencyList, { data: efficiencyList, isLoading, isSuccess }] =
    useGetVehicleEfficiencyListMutation();

    const theme = useTheme();
    useEffect(async () => {
        auditPayload.message = "Reports > Low Efficiency";
        auditPayload.action = "View";
        auditPayload.featureName = "Low Efficiency";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
    }, []);

    const calculateMonthHeader = (month) => {
        const d = new Date();
        d.setMonth(d.getMonth() - month);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[d.getMonth()] + " " + d.getFullYear();
    };

    
    let commonPayload = {};
    const onSubmitForm = (data) => {
      setNewPage(0);
      setRowsPerPage(25);
      commonPayload.reselId = resellerId;
      commonPayload.regionId = data.regionId;
      commonPayload.dealerId = data.dealerId;
      commonPayload.customerId = data.customerId;
      commonPayload.orgId = data.fleetId;
      commonPayload.vehicleId = data.vehicleId;
      commonPayload.search = "";
      let parts = data.toDate.split(/[- :]/);
      let month = parts[1];
      let year = parts[0];
      let currentdate = new Date();
      let cur_month = currentdate.getMonth() + 1;
      let cur_year = currentdate.getFullYear();
      if(cur_month == month && year == cur_year){
        commonPayload.startDate = moment().subtract(1,"month").startOf("month").format("YYYY-MM-DD 00:00:00");
        commonPayload.endDate =  moment().subtract(1,"month").endOf("month").format("YYYY-MM-DD 23:59:59");
      }else{
        commonPayload.startDate = data.fromDate;
        commonPayload.endDate = data.toDate;
      }     

      commonPayload.orderDir = "desc";
      commonPayload.start = newPage;
      commonPayload.length = rowsPerPage;
      commonPayload.size = rowsPerPage;
      //Service Call
      setPayload(commonPayload);
      getEfficiencyList(commonPayload);
  
      auditPayload.message = "Reports > Low Efficiency > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
      auditPayload.action = "Submit";
      auditPayload.featureName = "Low Efficiency";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "VehicleEfficiency/LowEfficencyCSV.csv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Low Efficiency > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Low Efficiency";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
        if (payload.search.length >= 3) {
            getEfficiencyList(payload);
        } else if (payload.search.length === 0) {
            getEfficiencyList(payload);
        }
      };
      //Page Change
      const pageChange = (newPage, rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        payload.size = rowsPerPage;
        if(totalElements < rowsPerPage * newPage){
          setNewPage(0)
          payload.page = 0;
          getEfficiencyList(payload);
        }else{
          setNewPage(newPage);
          payload.page = newPage;
          getEfficiencyList(payload);
        }
      };
    
    
    
      useEffect(() => {
        if (isSuccess) {
          setResponseData([]);
          if (efficiencyList.data !== null) {
            setResponseData(efficiencyList.data.content);
            setTotalElements(efficiencyList.data.totalElements);
            setTotalPages(efficiencyList.data.totalPages);
          }
        }
      }, [isSuccess]);

    return (
        <StyledPageLayout>
            <Breadcrumb
                mainDiv="Reports"
                subDiv="Low Efficiency"
                includePopup={true}
            ></Breadcrumb>
            <Box>

            <FilterDropdown
        getData={onSubmitForm}
        status={false}
        monthPicker={true}
        allowedMonth = {3}
        rangeLastMonth={true}
      />
                <StyledCardShadow sx={{ mt: 2 }}>
                    <CardContent sx={{ py: 1 }}>
                        <DataTable
                            columns={columns}
                            payload={payload}
                            exportWithAll={true}
                            includeSearchBox={true}
                            onChangeXcl={onChangeXcl}
                            handleChange={handleSearch}
                            data={responseData}
                            styles={{ maxWidth: "100vw" }}
                            numberOfStickyColumns={1}
                            page={newPage}
                            rowsPerPage={rowsPerPage}
                            callbackChangePage={pageChange}
                            totalElements={totalElements}
                            totalPages={totalPages}
                            isLoading={isLoading}
                        />
                    </CardContent>
                </StyledCardShadow>
            </Box>
        </StyledPageLayout>
    );
}

export default VehicleEfficiencyReport;