import * as React from "react";
import { useState } from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import {
  StyledTab,
  StyledTabBox,
  StyledTabsGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import RouteSummaryFleetwiseReport from "../../components/Reports/RouteSummaryFleetwiseReport";
import RouteSummaryTripWiseReport from "./RouteSummaryTripWiseReport";
import { Box } from "@mui/system";
import {
  Typography,
  ToggleButtonGroup,
  Paper,
  Container,
  CardContent,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import {
    useGetSummaryTripWiseListMutation,
  useGetSummaryFleetWiseListMutation,
  useAuditLogMutation,
} from "../../store/slice/ApiSlice";

import { useRef } from "react";
import moment from "moment";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
const text =
  "This Report gets the Route trip Summary details for Fleet-wise or Trip-wise.";

const RouteSummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId,
    orgName: orgName,
    userName: userName,
  };

  const [hideDetails, setHidedetails] = useState("FleetWise");
  const [alignment, setAlignment] = React.useState("FleetWise");
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleChange = (event) => {
    setHidedetails(event.target.value);
  };

  
  // Get Data from New Dropdown

  //Fleet Wise
  const [
    getFleetWiseTrip,
    { data: fleetWiseData, isLoading: fleetWiseLoading, isSuccess:isFleetSuccess },
  ] = useGetSummaryFleetWiseListMutation();

  const [
    getTripWise,
    { data: tripWiseData, isLoading: tripWiseLoading, isSuccess:isTripSuccess },
  ] = useGetSummaryTripWiseListMutation();

  
  const [payload, setPayload] = useState(null);

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.orderdir = "desc";
    commonPayload.page = 0;
    commonPayload.size = 25;
    //Service Call
    setPayload(commonPayload);
    if (alignment === "FleetWise") {
      commonPayload.orderColumn = "START_TIME";
      getFleetWiseTrip(commonPayload);
      auditPayload.message = "Reports > Route Trip Summary > Fleet Wise > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedMonthName+" > "+"Submit";
    } else {
      //Day wise
      commonPayload.columnName = "START_TIME";
      getTripWise(commonPayload);
      auditPayload.message = "Reports > Route Trip Summary > Trip Wise > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedMonthName+" > "+"Submit";
    }
    dashboardGen(data);

    auditPayload.action = "Submit";
    auditPayload.featureName = "Route Trip Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  React.useEffect(() => {
    if (alignment === "FleetWise") {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.orderColumn = "START_TIME";
        getFleetWiseTrip(newobj);
      }
    } else {
      //Trip wise
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.columnName = "START_TIME";
        getTripWise(newobj);
      }
    }
  }, [alignment]);

  //Fleet Wise data success;
  const [vehicleData, setVehicleData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [responseData, setResponseData] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [totalTripElements, setTotalTripElements] = useState(5);
  const [totalTripPages, setTotalTripPages] = useState(1);

  
  let iframeReload = useRef();
  const [value, setValue] = useState(0);
  //Montra Monthly Dashboard
  const dashboardGen = (formData) => {
    var regionId = "";
    var customerId = "";
    var dealerId = "";
    var fleetId = "";
    var vehicleId = "";
    if (formData !== null) {
      if (formData?.regionId !== "-1") {
        regionId = '"REGION_ID"=' + formData?.regionId + "and";
      } else {
        regionId = '"REGION_ID"!=5' + "and";
      }

      if (formData?.dealerId !== "-1") {
        dealerId = '"DEALER_ID"=' + formData?.dealerId + "and";
      } else {
        dealerId = "";
      }

      if (formData?.customerId !== "-1") {
        customerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1") {
        fleetId = '"ORG_ID"=' + formData?.fleetId + "and";
      } else {
        fleetId = "";
      }
      if (formData?.vehicleId !== "-1") {
        vehicleId = formData?.vehicleId;
      } else {
        vehicleId = "";
      }
    }
    
  };

  const handleTapChanage = (event, newValue) => {
    setValue(newValue);
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText.trim();
    if (payload.search.length >= 3) {
        getFleetWiseTrip(payload);
    } else if (payload.search.length === 0) {
        getFleetWiseTrip(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getFleetWiseTrip(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getFleetWiseTrip(payload);
    }
  };

//Page Change
const tripPageChange = (newPage, rowsPerPage) => {
  setRowsPerPage(rowsPerPage);
  payload.size = rowsPerPage;
  
  if(totalTripElements < rowsPerPage * newPage){
    setNewPage(0)
    payload.page = 0;
    getTripWise(payload);
  }else{
    setNewPage(newPage);
    payload.page = newPage;
    getTripWise(payload);
  }
};

  React.useEffect(() => {
    if (isFleetSuccess) {
      setResponseData([]);
      if (fleetWiseData?.data !== null) {
        setResponseData(fleetWiseData?.data?.content);
        setTotalElements(fleetWiseData?.data?.totalElements);
        setTotalPages(fleetWiseData?.data?.totalPages);
      } else {
        setResponseData([]);
      }
    }
  }, [isFleetSuccess]);

  React.useEffect(() => {
    if (isTripSuccess) {
      setTripData([]);
      if (tripWiseData?.data !== null) {
        setTripData(tripWiseData?.data?.content);
        setTotalTripElements(tripWiseData?.data?.totalElements);
        setTotalTripPages(tripWiseData?.data?.totalPages);
     

      } else {
        setTripData([]);
      }
    }
  }, [isTripSuccess]);



  // const [newPage, setNewPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [totalElements, setTotalElements] = useState(5);
  // const [totalPages, setTotalPages] = useState(1);

  return (
    <StyledPageLayout>

      <Breadcrumb
        mainDiv="Reports"
        subDiv="Route Summary Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Route Summary Report"} />

      {/* Dropdown */}

      <FilterDropdown
        getData={onSubmitForm}
        status={false}

        // value={value}
          allowDays={31}
          allowToday={false}
          last6Month={false}
          time = {true}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
          last31={false}
          last7 = {false}
          isShowAll={false}
      />
      <Box sx={{ m: 0 }}>
        {/* {resellerId === 110224 && (
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              variant="scrollable"
              onChange={handleTapChanage}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <StyledTab label={"Route Summary Report"} key={0} />
              {
                orgType === 2 && <StyledTab label={"Monthly Analytics Report"} key={1} />
              }
            </StyledTabsGroup>
          </StyledTabBox>
        )} */}
        {value === 0 && (
          <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            onClick={handleChange}
            size="small"
            sx={{ mt: 2 }}
          >
            <StyledToggleButton value={"FleetWise"}>
              Fleet Wise
            </StyledToggleButton>
            <StyledToggleButton value={"TripWise"}>Trip Wise</StyledToggleButton>
          </StyledToggleButtonGroup>
        )}
      </Box>
      {value === 0 && (
        <>
          {hideDetails === "FleetWise" && (
            <RouteSummaryFleetwiseReport
              data={responseData}
              payload={payload}
              isLoadingOut={fleetWiseLoading}
              handleSearch={handleSearch}
              pageChange={pageChange}
              newPage={newPage}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              footerData={fleetWiseData}
            />
          )}
          {hideDetails === "TripWise" && (
            <RouteSummaryTripWiseReport
              data={tripData}
              payload={payload}
              isLoadingOut={tripWiseLoading}
              pageChange={tripPageChange}
              newPage={newPage}
              rowsPerPage={rowsPerPage}
              totalElements={totalTripElements}
              footerData={tripWiseData}
            />
          )}
        </>
      )}

      
    </StyledPageLayout>
  );
};
export default RouteSummaryReport;
