import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import vehicleServices from "../../services/vehicleServices";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { CardContent, CardHeader, Typography } from "@mui/material";
import logServices from "../../services/logService";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useAuditLogMutation,
  useVehicleDeleteMutation,
  useVehicleListMutation,
} from "../../store/slice/ApiSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledModel from "../../Utils/StyledModel";
import { useLocation, useNavigate } from "react-router-dom";

const ListVehicle = ({ onEditVeh, menuUser, onViewVeh }) => {

  const navigate = useNavigate();
  const location = useLocation();
  let hashId = location.hash;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;
  let roleId = userData.roleId;
  
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [deleteItem, setDeleteItem] = useState(null);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Region",
        accessor: "regionName",
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
      },
      {
        Header: "Customer",
        accessor: "customerName",
      },
      {
        Header: "Fleet",
        accessor: "fleetName",
        width: '150',
      },
      {
        Header: "Vehicle VIN",
        accessor: "chassisNo",
        width: '180',
      },
      {
        Header: "Vehicle Plate",
        accessor: "number",
        width: '180',
      },
      {
        Header: "Vehicle Name",
        accessor: "vehicleName",
      },
      {
        Header: "Device IMEI",
        accessor: "imei",
        width: '180',
      },
      {
        Header: "First No",
        accessor: "mobileNumber1",
      },
      {
        Header: "Last Connected",
        accessor: "vehGPSdate",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "KYC Status",
        accessor: "kycStatus",
      },
      {
        Header: "Description",
        accessor: "kycDescription",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ];

      const threeWheelerColumns = [
        {
          Header: "Region",
          accessor: "regionName",
        },
        {
          Header: "Dealer",
          accessor: "dealerName",
        },
        {
          Header: "Customer",
          accessor: "customerName",
        },
        {
          Header: "Fleet",
          accessor: "fleetName",
          width: '150',
        },
        {
          Header: "Vehicle VIN",
          accessor: "chassisNo",
          width: '180',
        },
        {
          Header: "Vehicle Plate",
          accessor: "number",
          width: '180',
        },
        {
          Header: "Vehicle Name",
          accessor: "vehicleName",
        },
        {
          Header: "Device IMEI",
          accessor: "imei",
          width: '180',
        },
        {
          Header: "Vehicle Type",
          accessor: "type",
          width: '180',
        },
        {
          Header: "First No",
          accessor: "mobileNumber1",
        },
        {
          Header: "Last Connected",
          accessor: "vehGPSdate",
        },
        {
          Header: "Created Date",
          accessor: "createdDate",
        },
        {
          Header: "KYC Status",
          accessor: "kycStatus",
        },
        {
          Header: "Description",
          accessor: "kycDescription",
        },
        {
          Header: "Action",
          accessor: "buttonEditDelete",
        },
      ];
      

  if (resellerId === 110050 && (roleId === 7 || roleId === 17)) {
    // Find the index of the "Action" column
    const actionIndex = threeWheelerColumns.findIndex(column => column.accessor === "buttonEditDelete");

    // Create a new array with the new columns inserted
    const columnsWithOwners = [
      ...threeWheelerColumns.slice(0, actionIndex), // Columns before "Action"
      {
        Header: "Owner Name",
        accessor: "riderName",
      },
      {
        Header: "Owner Mobile Number",
        accessor: "riderMobileNumber",
      },
      ...threeWheelerColumns.slice(actionIndex), // Columns including and after "Action"
    ];

    return columnsWithOwners;
  }
  return resellerId === 110050 ? threeWheelerColumns : baseColumns;
}, [resellerId, roleId]); // Ensure dependencies are included

  let initialState1 = {};
  var arr = [];
if( resellerId === 110224 || resellerId=== 110050) {
    arr.push("vehicleName");

    initialState1.hiddenColumns= arr;
}
  
  if (orgType === 3) {
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 5){
    arr.push("regionName");
    arr.push("dealerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 1){
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    arr.push("fleetName");
    initialState1.hiddenColumns = arr;
  }


  useEffect(async () => {
    auditPayload.message = "Admin > Vehicle > List Vehicle";
    auditPayload.action = "View";
    auditPayload.featureName = "Vehicle";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  // Get Data from New Admin Dropdown
  const [getVehicleList, { data: vehicleListData, isLoading, isSuccess }] =
    useVehicleListMutation();
  let commonPayload = {};

  //get Data
  const getData = (data) => {
    var dataStr = {};
    dataStr.edit = false;
    dataStr.delete = false;
    dataStr.view = false;
    dataStr.excel = false;
    dataStr.pdf = false;
    var strval;
    strval = menuUser.split(",");
    for (var i = 0; i < strval.length; i++) {
      if (parseInt(strval[i]) === 4) {
        dataStr.edit = true;
      }
      if (parseInt(strval[i]) === 3) {
        dataStr.delete = true;
      }
      if (parseInt(strval[i]) === 6) {
        dataStr.pdf = true;
      }
      if (parseInt(strval[i]) === 5) {
        dataStr.excel = true;
      }
      if (parseInt(strval[i]) === 2) {
        dataStr.view = true;
      }
    }
    setCommonFilter(dataStr);
    commonPayload.resellerId = resellerId;
    commonPayload.customerId = data.customer;
    commonPayload.fleetId = data.fleet;
    commonPayload.vehicleId = data.vehicle;
    commonPayload.dealerId = data.dealer;
    commonPayload.deviceId = -1;
    commonPayload.regionId = data.region;
    commonPayload.orgType = data.orgType;
    commonPayload.imei = data.imei;
    commonPayload.vehicleActStatus = data.customerAssigned;
    commonPayload.devicelastconnectStatus = -1;
    commonPayload.deviceModel = -1;
    commonPayload.vehicleModel = -1;
    commonPayload.dateOnOrbefore = data.createDate;
    commonPayload.page = 0;
    commonPayload.size = 25;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.searchText = "";
    commonPayload.columnName = "VEHICLE_NO";
    commonPayload.orderdir = "desc";
    commonPayload.vehicleType = data.vehicleType;
    //Service Call
    setPayload(commonPayload);
    getVehicleList(commonPayload);


    auditPayload.message = "Admin > Vehicle > List Vehicle > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+">"+data.imei+" > "+data.custAssignName+" > "+data.selectedPeriod+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
       ( resellerId === 110050 && (roleId === 7 || roleId === 17) ? "vehicle/vehicleList3WXLS.xls?resellerId=":
        "vehicle/vehicleListXLS.xls?resellerId=")
        +
        payload.resellerId +
        "&searchText=" +
        payload.searchText +
        "&orgType=" +
        payload.orgType +
        "&customerId=" +
        payload.customerId +
        "&fleetId=" +
        payload.fleetId +
        "&vehicleId=" +
        payload.vehicleId +
        "&deviceId=" +
        payload.deviceId +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&vehicleActStatus=" +
        payload.vehicleActStatus +
        "&dateOnOrbefore=" +
        payload.dateOnOrbefore +
        "&deviceModel=" +
        payload.deviceModel +
        "&devicelastconnectStatus=" +
        payload.devicelastconnectStatus +
        "&vehicleModel=" +
        payload.vehicleModel +
        "&vehicleType=" +
        payload.vehicleType +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);
      auditPayload.message = "Admin > Vehicle > List > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Vehicle";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const onChangePdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "vehicle/vehicleListXLS.xls?resellerId=" +
        payload.resellerId +
        "&searchText=" +
        payload.searchText +
        "&orgType=" +
        payload.orgType +
        "&customerId=" +
        payload.customerId +
        "&fleetId=" +
        payload.fleetId +
        "&vehicleId=" +
        payload.vehicleId +
        "&deviceId=" +
        payload.deviceId +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&vehicleActStatus=" +
        payload.vehicleActStatus +
        "&dateOnOrbefore=" +
        payload.dateOnOrbefore +
        "&deviceModel=" +
        payload.deviceModel +
        "&devicelastconnectStatus=" +
        payload.devicelastconnectStatus +
        "&vehicleModel=" +
        payload.vehicleModel +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);
      var request = {};

      auditPayload.message = "Admin > Vehicle > List > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "Vehicle";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.size = 25;
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      getVehicleList(payload);
    } else if (payload.searchText.length === 0) {
      getVehicleList(payload);
      setNewPage(0);
      setRowsPerPage(25);
      payload.page = 0;
      payload.size = 25;
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getVehicleList(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (vehicleListData.data !== null) {
        setResponseData(vehicleListData.data.content);
        setTotalElements(vehicleListData.data.totalElements);
        setTotalPages(vehicleListData.data.totalPages);
      }
    }
  }, [isSuccess]);

  // const handleSubmit = async (
  //   seltdOrgTypeId,
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdLastOrgId,
  //   seltdVehId,
  //   seltdImei,
  //   selCustomersAssigned,
  //   selCreatedDate,
  //   startDate,
  //   endDate,
  //   seltdDealerId,
  //   seltdRegionId
  // ) => {
  //   var request = {};
  //   request.resellerId = seltdResellerId;
  //   request.customerId = seltdCustomerId;
  //   request.fleetId = seltdFleetId;
  //   request.vehicleId = seltdVehId;
  //   request.dealerId = seltdDealerId;
  //   request.deviceId = -1;
  //   request.regionId = seltdRegionId;
  //   request.orgType = seltdOrgTypeId;
  //   request.imei = seltdImei;
  //   request.vehicleActStatus = -1;
  //   request.devicelastconnectStatus = -1;
  //   request.deviceModel = -1;
  //   request.vehicleModel = -1;
  //   request.dateOnOrbefore = selCreatedDate;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.startDate = startDate;
  //   request.endDate = endDate;
  //   request.searchText = "";
  //   request.columnName = "VEHICLE_NO";
  //   request.orderdir = "desc";
  //   setLoading("");
  //   // request.searchText =searchText;
  //   // if(columnName===undefined){

  //   //   request.columnName = "VEHICLE_NO";

  //   // }else{
  //   //   request.columnName = columnName;

  //   // }

  //   // if(orderdir===undefined){
  //   //   request.orderdir = "desc";

  //   // }else{

  //   //   request.orderdir = orderdir;
  //   // }
  //   // if(searchText !== ""){
  //   //   request.searchText = searchText;
  //   // }else{
  //   //    request.searchText = "";
  //   // }

  //   setSelectedDropdownAry(request);
  //   setExportRequest(request);

  //   try {
  //     const responseData = (await vehicleServices.listVehicle(request)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  //ACTION VIEW
  const onView = async (itemEdited) => {
    onViewVeh(itemEdited);

    auditPayload.message = "Admin > Vehicle > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Vehicle";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const onEdit = (itemEdited) => {
    onEditVeh(itemEdited);

    auditPayload.message = "Admin > Vehicle > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Edit";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  //ACTION DELETE
  const onDelete = async (itemDeleted) => {
    navigate('#delete')
    setDeleteItem(itemDeleted);
   };

  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useVehicleDeleteMutation();
  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    delteItem(payload);

    auditPayload.message = "Admin > DeleteVehicle";
    auditPayload.action = "View";
    auditPayload.featureName = "Vehicle";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getVehicleList(payload);
    }
  }, [isDeleteSuccess]);
  // const fetchVehicleDetails = async (
  //   searchText,
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdLastOrgId,
  //   seltdVehId,
  //   columnName,
  //   orderdir,
  //   seltdOrgTypeId
  // ) => {
  //   var request = {};

  //   request.orgId = selectedDropdownAry.orgId;
  //   request.resellerId = selectedDropdownAry.resellerId;

  //   request.customerId = selectedDropdownAry.customerId;
  //   request.fleetId = selectedDropdownAry.fleetId;
  //   request.vehicleId = selectedDropdownAry.vehicleId;
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.deviceId = selectedDropdownAry.deviceId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   request.orgType = selectedDropdownAry.orgType;
  //   request.imei = selectedDropdownAry.imei;
  //   request.vehicleActStatus = selectedDropdownAry.vehicleActStatus;
  //   request.devicelastconnectStatus =
  //     selectedDropdownAry.devicelastconnectStatus;
  //   request.deviceModel = selectedDropdownAry.deviceModel;
  //   request.vehicleModel = selectedDropdownAry.vehicleModel;
  //   request.dateOnOrbefore = selectedDropdownAry.dateOnOrbefore;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;

  //   request.searchText = searchText;
  //   if (columnName === undefined) {
  //     request.columnName = "VEHICLE_NO";
  //   } else {
  //     request.columnName = columnName;
  //   }

  //   if (orderdir === undefined) {
  //     request.orderdir = "desc";
  //   } else {
  //     request.orderdir = orderdir;
  //   }
  //   try {
  //     const responseData = (await vehicleServices.listVehicle(request)).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //   } catch (error) {}
  // };

  // const handleSearch = (searchText) => {
  //   var selObj = { ...selectedDropdownAry };

  //   fetchVehicleDetails(
  //     searchText,
  //     selObj.seltdResellerId,
  //     selObj.seltdLastOrgId
  //   );
  // };
  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   var obj = { sortColumn };
  //   //fetchUserDetails(sortColumn.id);
  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "chassisNo") {
  //     if (obj1.chassisNo !== undefined && obj1.chassisNo === "asc") {
  //       obj1.chassisNo = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.chassisNo = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "CHASSIS_NO";
  //   } else if (obj.sortColumn.id === "imei") {
  //     if (obj1.imei !== undefined && obj1.imei === "asc") {
  //       obj1.imei = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.imei = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "IMEI";
  //     //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   }
  //   // else if(obj.sortColumn.id==="userName"){
  //   //   if(obj1.userName!==undefined && obj1.userName==="asc"){
  //   //     obj1.userName="desc";
  //   //     orderdir="desc";
  //   //   }else{
  //   //     obj1.userName="asc";
  //   //     orderdir="asc";
  //   //   }
  //   //   seltdsrtngcolumn="USER_NAME";
  //   //   //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   // }
  //   // else if(obj.sortColumn.id==="email"){
  //   //   if(obj1.email!==undefined && obj1.email==="asc"){
  //   //     obj1.email="desc";
  //   //     orderdir="desc";
  //   //   }else{
  //   //     obj1.email="asc";
  //   //     orderdir="asc";
  //   //   }
  //   //   seltdsrtngcolumn="EMAIL";
  //   //   //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   //}
  //   setArraySortOrder(obj1);
  //   var selObj = { ...selectedDropdownAry };

  // fetchVehicleDetails(
  //   "",
  //   seltdsrtngcolumn,
  //   orderdir,
  //   selObj.seltdResellerId,
  //   selObj.seltdCustomerId,
  //   selObj.seltdLastOrgId,
  //   selObj.seltdOrgTypeId,
  //   selObj.seltdFleetId,
  //   selObj.seltdVehId
  // );
  // };

  // const callbackLoadFn = async (
  //   seltdOrgTypeId,
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdLastOrgId,
  //   seltdVehId,
  //   seltdImei,
  //   selCustomersAssigned,
  //   selCreatedDate,
  //   startDate,
  //   endDate,
  //   seltdDealerId,
  //   seltdRegionId
  // ) => {
  //   var dataStr = {};
  //   dataStr.edit = false;
  //   dataStr.delete = false;
  //   dataStr.view = false;
  //   dataStr.excel = false;
  //   dataStr.pdf = false;
  //   var strval;
  //   strval = menuUser.split(",");
  //   for (var i = 0; i < strval.length; i++) {
  //     if (parseInt(strval[i]) === 3) {
  //       dataStr.edit = true;
  //     }
  //     if (parseInt(strval[i]) === 4) {
  //       dataStr.delete = true;
  //     }
  //     if (parseInt(strval[i]) === 6) {
  //       dataStr.pdf = true;
  //     }
  //     if (parseInt(strval[i]) === 5) {
  //       dataStr.excel = true;
  //     }
  //   }
  //   setCommonFilter(dataStr);
  //   if (loadStatus === true) {
  //     var request = {};
  //     request.seltdResellerId = seltdResellerId;
  //     request.resellerId = seltdResellerId;
  //     request.seltdCustomerId = seltdCustomerId;
  //     request.customerId = seltdCustomerId;

  //     request.fleetId = seltdFleetId;
  //     request.vehicleId = seltdVehId;
  //     request.dealerId = seltdDealerId;
  //     request.deviceId = -1;
  //     request.regionId = seltdRegionId;
  //     request.orgType = seltdOrgTypeId;
  //     request.imei = seltdImei;
  //     request.vehicleActStatus = selCustomersAssigned;
  //     request.devicelastconnectStatus = -1;
  //     request.deviceModel = -1;
  //     request.vehicleModel = -1;
  //     request.dateOnOrbefore = selCreatedDate;
  //     request.columnName = "VEHICLE_NO";
  //     request.orderdir = "desc";
  //     request.page = newPage;
  //     request.size = rowsPerPage;
  //     request.startDate = startDate;
  //     request.endDate = endDate;

  //     request.searchText = "";
  //     setSelectedDropdownAry(request);
  //     setExportRequest(request);

  //     try {
  //       // const responseData = (await vehicleServices.listVehicle(request)).data;
  //       // setResponseData(responseData.data.content);
  //       // setLoading(responseData.resultText);
  //       // setTotalElements(responseData.data.totalElements);
  //       // setTotalPages(responseData.data.totalPages);
  //     } catch (error) {}
  //   }
  //   setLoadStatus(false);
  // };

  // const onChangeXcl = async () => {
  //   var request = {};
  //   request.resellerId = exportRequest.resellerId;
  //   request.customerId = exportRequest.customerId;
  //   request.fleetId = exportRequest.fleetId;
  //   request.vehicleId = exportRequest.vehicleId;
  //   request.dealerId = exportRequest.dealerId;
  //   request.orgType = exportRequest.orgType;
  //   request.deviceId = exportRequest.deviceId;
  //   request.regionId = exportRequest.regionId;
  //   request.vehicleActStatus = exportRequest.vehicleActStatus;
  //   request.devicelastconnectStatus = exportRequest.devicelastconnectStatus;
  //   request.deviceModel = exportRequest.deviceModel;
  //   request.vehicleModel = exportRequest.vehicleModel;
  //   request.dateOnOrbefore = exportRequest.dateOnOrbefore;
  //   request.startDate = exportRequest.startDate;
  //   request.endDate = exportRequest.endDate;
  //   request.searchText = "";

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "vehicle/vehicleListXLS.xls?resellerId=" +
  //       request.resellerId +
  //       "&searchText=" +
  //       request.searchText +
  //       "&orgType=" +
  //       request.orgType +
  //       "&customerId=" +
  //       request.customerId +
  //       "&fleetId=" +
  //       request.fleetId +
  //       "&vehicleId=" +
  //       request.vehicleId +
  //       "&deviceId=" +
  //       request.deviceId +
  //       "&dealerId=" +
  //       request.dealerId +
  //       "&regionId=" +
  //       request.regionId +
  //       "&vehicleActStatus=" +
  //       request.vehicleActStatus +
  //       "&dateOnOrbefore=" +
  //       request.dateOnOrbefore +
  //       "&deviceModel=" +
  //       request.deviceModel +
  //       "&devicelastconnectStatus=" +
  //       request.devicelastconnectStatus +
  //       "&vehicleModel=" +
  //       request.vehicleModel +
  //       "&startDate=" +
  //       request.startDate +
  //       "&endDate=" +
  //       request.endDate +
  //       " ";
  //     window.open(url);
  //     var request = {};

  //     request.message = "Admin > ListVehicle > XLS";
  //     request.action = "View";
  //     request.featureName = "Vehicle";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //     fetch(url, {
  //       headers: {
  //         Authorization:
  //           "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
  //       },
  //     }) // FETCH BLOB FROM IT
  //       .then((response) => {
  //         response.blob();
  //       })
  //       .then((blob) => {
  //         if (undefined !== blob) {
  //           var _url = window.URL.createObjectURL(blob);
  //           var a = document.createElement("a");
  //           var link = document.body.appendChild(a);
  //           link.download = "VehicleList";
  //           link.href = _url;
  //           link.method = "post";
  //           link.click();
  //           window.URL.revokeObjectURL(_url);
  //           a.removeAttribute("download");
  //           a.removeAttribute("post");
  //           a.removeAttribute("href");
  //           document.body.removeChild(a);
  //         } else {
  //           //alert("unAuthorized error");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {}
  // };

  // const onChangePdf = async () => {
  //   var request = {};
  //   request.resellerId = exportRequest.resellerId;
  //   request.customerId = exportRequest.customerId;
  //   request.fleetId = exportRequest.fleetId;
  //   request.vehicleId = exportRequest.vehicleId;
  //   request.dealerId = exportRequest.dealerId;
  //   request.orgType = exportRequest.orgType;
  //   request.deviceId = exportRequest.deviceId;
  //   request.regionId = exportRequest.regionId;
  //   request.vehicleActStatus = exportRequest.vehicleActStatus;
  //   request.devicelastconnectStatus = exportRequest.devicelastconnectStatus;
  //   request.deviceModel = exportRequest.deviceModel;
  //   request.vehicleModel = exportRequest.vehicleModel;
  //   request.dateOnOrbefore = exportRequest.dateOnOrbefore;
  //   request.startDate = exportRequest.startDate;
  //   request.endDate = exportRequest.endDate;
  //   request.searchText = "";

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "vehicle/vehicleListPDF.pdf?resellerId=" +
  //       request.resellerId +
  //       "&searchText=" +
  //       request.searchText +
  //       "&orgType=" +
  //       request.orgType +
  //       "&customerId=" +
  //       request.customerId +
  //       "&fleetId=" +
  //       request.fleetId +
  //       "&vehicleId=" +
  //       request.vehicleId +
  //       "&deviceId=" +
  //       request.deviceId +
  //       "&dealerId=" +
  //       request.dealerId +
  //       "&regionId=" +
  //       request.regionId +
  //       "&vehicleActStatus=" +
  //       request.vehicleActStatus +
  //       "&dateOnOrbefore=" +
  //       request.dateOnOrbefore +
  //       "&deviceModel=" +
  //       request.deviceModel +
  //       "&devicelastconnectStatus=" +
  //       request.devicelastconnectStatus +
  //       "&vehicleModel=" +
  //       request.vehicleModel +
  //       "&startDate=" +
  //       request.startDate +
  //       "&endDate=" +
  //       request.endDate +
  //       " ";
  //     window.open(url);
  //     var request = {};

  //     request.message = "Admin > ListVehicle > PDF";
  //     request.action = "View";
  //     request.featureName = "Vehicle";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //     fetch(url, {
  //       headers: {
  //         Authorization:
  //           "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
  //       },
  //     }) // FETCH BLOB FROM IT
  //       .then((response) => {
  //         response.blob();
  //       })
  //       .then((blob) => {
  //         if (undefined !== blob) {
  //           var _url = window.URL.createObjectURL(blob);
  //           var a = document.createElement("a");
  //           var link = document.body.appendChild(a);
  //           link.download = "VehicleList";
  //           link.href = _url;
  //           link.method = "post";
  //           link.click();
  //           window.URL.revokeObjectURL(_url);
  //           a.removeAttribute("download");
  //           a.removeAttribute("post");
  //           a.removeAttribute("href");
  //           document.body.removeChild(a);
  //         } else {
  //           //alert("unAuthorized error");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     //var url="http://localhost:8001/"+"vehicle/vehicleListPDF.pdf?resellerId="+request.resellerId+"&orgId="+request.orgId+"&page="+request.page+"&size="+request.size+"&searchText="+request.searchText+"&orgType="+request.orgType+"&customerId="+request.customerId+"&fleetId="+request.fleetId+"&vehicleId="+request.vehicleId+"&deviceId="+request.deviceId+"&columnName="+request.columnName+"&orderdir="+request.orderdir+"&dealerId="+request.dealerId+"&regionId="+request.regionId+"&vehicleActStatus="+request.vehicleActStatus+"&dateOnOrbefore="+request.dateOnOrbefore+"&deviceModel="+request.deviceModel+"&devicelastconnectStatus="+request.devicelastconnectStatus+"&vehicleModel="+request.vehicleModel+"&imei="+request.imei+" ";
  //     // var url="http://localhost:8001/"+"vehicle/vehicleListXLS.xls?resellerId="+request.resellerId+"&orgId="+request.orgId+"&page="+request.page+"&size="+request.size+"&searchText="+request.searchText+"&orgType="+request.orgType+"&customerId="+request.customerId+"&fleetId="+request.fleetId+"&vehicleId="+request.vehicleId+"&deviceId="+request.deviceId+"&columnName="+request.columnName+"&orderdir="+request.orderdir+"&dealerId="+request.dealerId+"&regionId="+request.regionId+"&vehicleActStatus="+request.vehicleActStatus+"&dateOnOrbefore="+request.dateOnOrbefore+"&deviceModel="+request.deviceModel+"&devicelastconnectStatus="+request.devicelastconnectStatus+"&vehicleModel="+request.vehicleModel+"&imei="+request.imei+" ";
  //     //window.open(url);
  //   } catch (error) {}
  // };

  // const onDelete = async (itemDeleted) => {
  //   try {
  //     await vehicleServices.deleteVehicle(itemDeleted.id);
  //     var request = {};

  //     request.message = "Admin > DeleteVehicle";
  //     request.action = "View";
  //     request.featureName = "Vehicle";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //     // await fetchVehicleDetails();
  //   } catch (err) {
  //     console.error("something went wrong");
  //   }
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   setLoading("");
  //   var request = {};
  //   request.resellerId = selectedDropdownAry.resellerId;
  //   request.customerId = selectedDropdownAry.customerId;
  //   request.fleetId = selectedDropdownAry.fleetId;
  //   request.vehicleId = selectedDropdownAry.vehicleId;
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.deviceId = selectedDropdownAry.deviceId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   request.orgType = selectedDropdownAry.regionId;
  //   request.vehicleActStatus = selectedDropdownAry.vehicleActStatus;
  //   request.devicelastconnectStatus =
  //     selectedDropdownAry.devicelastconnectStatus;
  //   request.deviceModel = selectedDropdownAry.deviceModel;
  //   request.vehicleModel = selectedDropdownAry.vehicleModel;
  //   request.dateOnOrbefore = selectedDropdownAry.dateOnOrbefore;
  //   request.columnName = selectedDropdownAry.columnName;
  //   request.orderdir = selectedDropdownAry.orderdir;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;
  //   request.searchText = "";

  //   try {
  //     const responseData = (await vehicleServices.listVehicle(request)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  return (
    <Box>
      {/* <CommonDropDown
        handleSmt={handleSubmit}
        callbackLoadFn={callbackLoadFn}
      /> */}
      <AdminDropdown
        getData={getData}
        regionDrop={true}
        dealerDrop={true}
        customerDrop={true}
        fleetDrop={true}
        vehicleDropD={true}
        vehicleDrop={true}
        createdate={true}
        allowDays={30}
        allowedMonth={24}
      />
      <StyledCardShadow sx={{ my: 2 }}>
        <CardHeader title={<StyledTableTitle>Vehicle List</StyledTableTitle>} />
        <CardContent sx={{ py: 0 }}>
          <DataTable
          initialState={initialState1}
            isLoading={isLoading}
            includeSearchBox={true}
            handleChange={handleSearch}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePdf}
            commonFilter={commonFilter}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            //callbck={handleSort}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
          />
        </CardContent>
      </StyledCardShadow>
      {deleteItem !== null && hashId === "#delete" && (
        <StyledModel data={deleteItem} deleteItem={deleteItems}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              Vehicle No: {deleteItem.number}
            </Typography>
          </Box>
        </StyledModel>
      )}
    </Box>
  );
};

export default ListVehicle;
