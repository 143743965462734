import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Typography, Stack, IconButton, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import {Button} from "@mui/material";
import {
  StyledCardShadow,
} from "../../theme/StyledEle";
import { useAuditLogMutation, useFaultCodeMutation } from "../../store/slice/ApiSlice";
import { HiMiniEye } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";


const ThreeWheelerFaultPopup=({payload,vehicleId})=>{

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
   

    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);

    payload.vehicleId=vehicleId;
    payload.reselId=resellerId;
    payload.custId=payload.customerId;
    payload.severity="-1";
    payload.status="-1";
    payload.start = newPage;
    payload.length = rowsPerPage;
    payload.page = newPage;
    payload.length = rowsPerPage;
    payload.search = "";
    if(payload.selperiod=='2'){
      payload.endDate=moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
    }
    const [ getFaultcode, {data:faultcodeDatta, isLoading, isSuccess} ] = useFaultCodeMutation();
  
    const theme = useTheme();
    const location = useLocation();
    const [auditLog]= useAuditLogMutation();
    let auditPayload = {
      //Audit Common Payload
      orgId:orgId,
      userId:userId, //User ID
      orgName:orgName, //Org Name
      userName:userName,
    }
    useEffect(async () => {
      auditPayload.message = 'Reports > FaultCode';
      auditPayload.action = 'View';
      auditPayload.featureName = 'Vehicle Summary';
      auditPayload.customerName = 'All';
      auditPayload.fleetName = "All";
      auditPayload.result = 'Success';
      auditPayload.access = 'Portal';
      auditPayload.severity = 'INFO';
      auditLog(auditPayload);
      getFaultcode(payload);
    }, []);
  
    const columns = useMemo(
      () => [
        {
          Header: "Vehicle Number",
          accessor: "vehicleno",
          width:180,
        },
        {
          Header: "Fault Code",
          accessor: "faultCode",
        },
        {
          Header: "Description",
          accessor: "faultDescription",
          width:250,
        },
        {
          Header: "Active/InActive",
          accessor: "status",
        },
        {
          Header: "Fault Location (Click To View Location In Map)",
          accessor: "faultLocation",
          width:350,
          Cell: ({ row, column, cell }) => {
            const refAddres = React.useRef(row);
            var refLink = React.useRef(row);
            var getAddressBtn = React.useRef(row);
            var toggleAddressBtn = React.useRef(row);
            const getAddress = async(e) => {
              if(refAddres.current.innerText === ''){
                var request = {};
                request.latLng = e.target.value;
                // rowid = e.target.id;
                const responseData = (await ReportServices.getGeocodeAddress(request)).data;
                refAddres.current.innerText = responseData;
                if(responseData !== ''){
                  refLink.current.style.display = 'none'
                  refAddres.current.style.display = 'block'
                  toggleAddressBtn.current.style.display = 'block'
                  getAddressBtn.current.style.display = 'none'
                  toggleAddressBtn.current.innerText='LatLng'
                }
              }
            }
  
            //Address toggle
            const ToggleAdderss = () => {
              if( refAddres.current.style.display === 'block' ){
                refAddres.current.style.display = 'none'
                refLink.current.style.display = 'block'
                toggleAddressBtn.current.innerText='Address'
              }else{
                refAddres.current.style.display = 'block'
                refLink.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
            return(
              <Box sx={{display:'flex', alignItems:'center'}} >
              <Box className='addresslink' id={row.original.faultLocation} sx={{display:'flex', alignItems:'center'}}>
                  <Button onClick={(e) => getAddress(e)} value={row.original.faultLocation} ref={getAddressBtn}>Address</Button>
                  <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank"  className="latlink"  ref={refLink} id={row.original.faultLocation}>{row.original.faultLocation}</a>
                  <a  href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank" className="address" ref={refAddres} id={row.original.faultLocation} key={row.original.faultLocation}></a>
              </Box>
          </Box>
            )
          },
        },
        {
          Header: "First Occurance",
          accessor: "firstOccurance",
          Cell:({row}) => {
            return (
              <Stack direction={'row'} alignItems={'center'}>
                  <Typography variant="body2">{row.original.firstOccurance}</Typography>         
              </Stack>
              
            )
          }
        },
        {
          Header: "Fault Closed  Date",
          accessor: "faultClosedDate",
        }
      ],
      []
    );

    const onChangeXcl = async () => {
          try {
                var url =
                  process.env.REACT_APP_API_BASE_URL +
                  "FaultCodeReport/FaultCodeXLS.xls?startDate=" +
                  payload.startDate +
                  "&endDate=" +
                  payload.endDate +
                  "&reselId=" +
                  payload.reselId +
                  "&orgId=" +
                  payload.orgId +
                  "&vehicleId=" +
                  payload.vehicleId +
                  "&status=" +
                  payload.status +
                  "&regionId=" +
                  payload.regionId +
                  "&dealerId=" +
                  payload.dealerId +
                  "&custId=" +
                  payload.custId +
                  "&severity=" +
                  payload.severity;
                  window.open(url);
      
                  auditPayload.message = 'Reports > Fault Code > XLS';
                  auditPayload.action = 'View';
                  auditPayload.featureName = 'Vehicle Summary';
                  auditPayload.customerName = 'All';
                  auditPayload.fleetName = 'All';
                  auditPayload.result = 'Success';
                  auditPayload.access = 'Portal';
                  auditPayload.severity = 'INFO';
                  auditLog(auditPayload);
            
      
            fetch(url, {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
              },
            }) // FETCH BLOB FROM IT
              .then((response) => {
                response.blob();
              })
              .then((blob) => {})
              .catch((err) => {});
          } catch (error) {}
       };

    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(10);
        payload.page = 0;
        payload.rowsPerPage = 10;
        if(payload.search.length >= 3){
          getFaultcode(payload);
        }else if(payload.search.length === 0){
          getFaultcode(payload);
        }
    };
    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
      setNewPage(newPage);
      setRowsPerPage(rowsPerPage);
      payload.page = newPage;
      payload.length = rowsPerPage;
      getFaultcode(payload);
    } 

    useEffect(() => {
        if(isSuccess){
          setResponseData([]);
          if(faultcodeDatta.data !== null){
            setResponseData(faultcodeDatta.data.content);
            setTotalElements(faultcodeDatta.data.totalElements);
            setTotalPages(faultcodeDatta.data.totalPages)
          }
        }
      },[isSuccess])

return (
    <Box>        
        <StyledCardShadow sx={{ mt: 2 }} >
         
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              columns={ columns}
              data={responseData}
              onChangeXcl={onChangeXcl}
              includeExportButton={true}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              specificPage={"EV Fault Code"}
              isLoading={isLoading}
              reload={true}

            />
          </CardContent>
        </StyledCardShadow>
      </Box>
);
}

export default ThreeWheelerFaultPopup;