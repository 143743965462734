import { InfoWindow, Marker, Polyline } from "@react-google-maps/api"
import { createRef, useEffect, useRef, useState } from "react"
import { ChargingDotIcon, GreenFirst, GreenLast, MovingDotIcon, OfflineDotIcon, StopDotIcon, PtoDotIcon, IdleDotIcon, TowingDotIcon } from "../Constans";
import { Box, Divider, Fab, Icon, Skeleton, Stack, Typography, alpha, useTheme } from "@mui/material";
//import InfowindowTrailsMarker from "./NewTreeView/Infowindow";
import { StyledInfoWindow } from "./Treeview/TreeviewStyledEle";
import { FaParking, FaRoad } from "react-icons/fa";
import { Fa0, FaIdeal, FaPlugCircleBolt,FaTractor } from "react-icons/fa6";
import { MdSignalWifiBad } from "react-icons/md";
import reportServices from "../../services/reportServices";
import  Nosignal  from '../../assets/images/StatusGif/offlineImage.png' 
//Start Marker
export const StartMarker = (props) => {
    //const startMarkerRef = useRef();
    const { data } = props;
    //Start Maker icon
    let icon = {
        url:Nosignal,
        scale:1,
        fillOpacity: 1,
    }
    return(
        data !== null &&
        <Marker 
            id={data?.lng}
            position={{lat:data?.lat, lng:data?.lng}}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}

//Offline Marker
export const OfflineMarker = (props) => {
    //const { position } = props;
    //Start Maker icon
    let icon = {
        url:Nosignal,
        scale:1,
        fillOpacity: 1,
    }
    return(
        <Marker 
            //id={data?.lng}
            position={{lat:20.5937, lng:78.9629}}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}


 
//End Marker
export const EndMarker = (props) => {
    const startMarkerRef = useRef(); 
    const { data } = props;

    //Start Maker icon
    let icon = {
        url:GreenLast,
        scale:1,
        fillOpacity: 1,
    }
    return(
        data !== null &&
        <Marker 
            id={data[0]?.lng}
            position={{lat:data[0]?.lat, lng:data[0]?.lng}}
            ref={startMarkerRef}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}

//Polyline
export const TrailsPolyline = (props) => {
    const { data } = props;
    //Polyline Options
    const options = {
        strokeColor:'#006400',
        strokeWeight:1.2,
        geodesic: true,
        icons: [{
            icon: {path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW},
            offset: '50%',
            repeat: '100px'
        }]
    }
    return (
        <Polyline 
            path={data}
            options={options}
        />
        )
}

//Dot Markers
export const TrailsMarkersDot = (props) => {
    const { data, lastMoving, firstMoving, autoRefresh } = props;
    const TrailsMakerRef = data.map(() => createRef());
    const [markerData, setMarkerData] = useState(null);
    const theme = useTheme();
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    const iconsType = {
        Stop:{
            icon:StopDotIcon
        },
        Moving:{
            icon:MovingDotIcon
        },
        Charging:{
            icon:ChargingDotIcon
        },
        Offline:{
            icon:OfflineDotIcon
        },
        PTO:{
            icon:PtoDotIcon
        },
        Idle:{
            icon:IdleDotIcon
        },
        Towing:{
            icon:TowingDotIcon
        },
    }
    //Start Maker icon
    let Lasticon = {
        url:GreenLast,
        scale:2,
        fillOpacity: 1,
        //anchor: new window.google.maps.Point(0, .02),
    }
    let Firsticon = {
        url:GreenFirst,
        scale:2,
        fillOpacity: 1,
        //anchor: new window.google.maps.Point(0, .02),
    }
    //Trails Marker Data
    const getMakerData = (makerData) => {
        if(makerData.current !== null && makerData.current !== undefined ){
            setMarkerData(makerData.current.props.dmarkerData); 
        }else{
            setMarkerData(makerData); 
        }
    }   

    //Close Marker
    const onClose = () => {
        setMarkerData(null);
      }

    const BackgroundStyle = {
        background: 
        (markerData !== null && markerData?.vehicleStatus === 'Stop') || (markerData !== null && markerData?.vehicleStatus) === '0' ? alpha(theme.palette.error.main, .9) : 
        (markerData !== null && markerData?.vehicleStatus === 'Charging') || (markerData !== null && markerData?.vehicleStatus) === '6'? alpha(theme.palette.warning.main, .9) : 
        (markerData  !== null && markerData?.vehicleStatus === 'Offline') || (markerData !== null && markerData?.vehicleStatus) === '4'? alpha(theme.palette.grey[700], .9) : 
        (markerData !== null && markerData?.vehicleStatus === 'Moving') || (markerData !== null && markerData?.vehicleStatus) === '1'? alpha(theme.palette.success.main, .9) :
        (markerData !== null && markerData?.vehicleStatus === 'PTO') || (markerData !== null && markerData?.vehicleStatus) === '5'? '#1fb2cc' :
        (markerData !== null && markerData?.vehicleStatus === 'Idle') || (markerData !== null && markerData?.vehicleStatus) === '3'? alpha(theme.palette.primary.main, .9) :
        (markerData !== null && markerData?.vehicleStatus === 'Towing') || (markerData !== null && markerData?.vehicleStatus) === '7'? '#9130B2' : alpha(theme.palette.grey[700], .9)
      }

      let [address, setAddress] = useState(null);

      useEffect(() => {
        if(markerData !== null) {
            getAddress();
        }
      },[markerData]);

      const getAddress = () => {
        setAddress(null);
            var requestN = {};
            requestN.latLng = markerData?.lat + "," + markerData?.lng;
            return reportServices.getGeocodeAddress(requestN)
              .then((resN) => {
                setAddress(resN.data);
            })
            .catch((err1) => {
            console.log(err1);
            });
      }

      useEffect(() => {
        if(autoRefresh){
            setAddress(null);
            setMarkerData(null);
        }else if(!autoRefresh && data !== null){
            if(data.length > 0){
                getMakerData(data.slice(-1)[0]);
            }
        }   
      },[autoRefresh, data])


    return(
        <>
            {
                 data !== null &&
                 data.map((markerItem, index) => 
                 <Marker
                 key={index+1}
                 id={data[0]?.lng}
                 position={{lat:markerItem.lat, lng:markerItem?.lng}}
                 ref={TrailsMakerRef[index]}
                 clickable
                 dmarkerData={markerItem}
                 icon={
                    firstMoving?.lat === markerItem.lat && firstMoving?.lng === markerItem.lng ? Firsticon :
                    lastMoving[0]?.lat === markerItem.lat && lastMoving[0]?.lng === markerItem.lng ? Lasticon :
                    iconsType[markerItem.vehicleStatus]?.icon}
                 onClick={() => getMakerData(TrailsMakerRef[index])}
                 /> )
            }
            {
                markerData !== null &&
                <InfoWindow
                    position={{lat:markerData.lat, lng:markerData.lng}}
                    options={{pixelOffset: new window.google.maps.Size(0,-60)}}
                    onCloseClick={onClose}
                >
                    <StyledInfoWindow>
                        <Box
                            className='infowindowHHeader'
                            sx={{...BackgroundStyle, width:'100%'}}
                        >
                           <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Vehicle No </Typography>
                            <Typography variant='subtitle2'>{markerData?.name}</Typography>
                          </Box>
                          <Fab disableTouchRipple size='small' 
                          sx={BackgroundStyle} 
                          >
                             <Icon>
                             {
                              markerData?.vehicleStatus === 'Stop' || markerData?.vehicleStatus === '0' ? <FaParking />: 
                              markerData?.vehicleStatus === 'Charging' || markerData?.vehicleStatus === '6'? <FaPlugCircleBolt /> : 
                              markerData?.vehicleStatus === 'Offline' || markerData?.vehicleStatus === '4'? <MdSignalWifiBad /> : 
                              markerData?.vehicleStatus === 'Moving' || markerData?.vehicleStatus === '1'? <FaRoad /> : 
                              markerData?.vehicleStatus === 'PTO' || markerData?.vehicleStatus === '5'? <FaTractor /> :
                              markerData?.vehicleStatus === 'Idle' || markerData?.vehicleStatus === '3'? <FaIdeal /> :
                              markerData?.vehicleStatus === 'Towing' || markerData?.vehicleStatus === '7'? <FaTractor /> : <FaParking />
                            }
                             </Icon>
                          </Fab>
                      </Stack>
                        </Box>
                        <Box sx={{padding:'5px 15px', width:'100%'}}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{width:'100%'}}>
                    <Box sx={{width:'100%'}}> 
                        <Stack direction={'column'} spacing={.5}>
                            <Box>
                              <Typography variant='caption'>Date</Typography>
                              <Typography variant='subtitle2'>{markerData?.dispDate}</Typography>
                            </Box>
                            <Box>
                              <Typography variant='caption'>Vehicle Status</Typography>
                              <Typography variant='subtitle2'>{markerData?.vehicleStatus}</Typography>
                            </Box>
                            <Box>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                   {resellerId !== 114663 && (<Box>
                                        <Typography variant='caption'>Odometer (km)</Typography>
                                        <Typography variant='subtitle2'>{markerData?.odometer}</Typography>
                                    </Box>)}
                                    {resellerId === 114663 && <Box>
                                        <Typography variant='caption'>HourMeter (HH:MM)</Typography>
                                        <Typography variant='subtitle2'>{markerData?.hourMeter}</Typography>
                                    </Box>}
                                    <Box>
                                        <Typography variant='caption'>SOC (%)</Typography>
                                        <Typography variant='subtitle2'>{markerData?.soc}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='caption'>Speed (km/h)</Typography>
                                        <Typography variant='subtitle2'>{markerData?.speed}</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>
                              <Typography variant='caption'>Address</Typography>
                              {
                                address !== null ? 
                                <Typography variant='subtitle2'> {address} </Typography> :
                                <Skeleton variant='subtitle2'/>
                              }
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
                        
                    </StyledInfoWindow>
                </InfoWindow>
            }
        </>
        
        
    )
}
