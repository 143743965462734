import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Typography } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import StyledTooltip from "../shared/StyledTooltip";

import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useMonthlyVehicleMutation,
} from "../../store/slice/ApiSlice";

const text =
  "This Report gets the trip & battery details for Vehicle-wise or Day-wise. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const MonthlySummaryvehicleWiseReport = ({
  data,
  payload,
  isLoadingOut,
  handleSearch,
  pageChange,
  newPage,
  rowsPerPage,
  totalElements
}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;



  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {

    auditPayload.message = "Reports > MonthlySummary-Vehiclewise";
    auditPayload.action = "View";
    auditPayload.featureName = "Monthly Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "S.No",
        width:80,
        Cell: ({ row }) => (
          <Typography id={row.index}>{row.index + 1}</Typography>
        ),
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleNumber",
        width:180
      },      {
        Header: "Customer Name",
        accessor: "customerName",
       
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "Months In Service",
            accessor: "monthsinService",
          },
          {
            Header: "Start Odometer (km)",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (km)",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled (km)",
            accessor: "tripDistance",
          },
          // {
          //   Header: "No Of Run Days",
          //   accessor: "numberofNORUNdays",
          // },
        ],
      },
      {
        Header: " Top Line Metrics / Asset Utilization",
        columns: [
          // {
          //   Header: "Avg No Of Trips/ Run Day",
          //   accessor: "averageNumberofTrips",
          // },
          // {
          //   Header: "Avg Distance (km)/Run Day",
          //   accessor: "averageDistance",
          // },

          // {
          //   Header: "Avg Idle Time/Run Day",
          //   accessor: "averageIdletime",
          // },
          {
            Header: "Offline Days",
            accessor: "offlineDays",
          },
          {
            Header: "Online Run Days",
            accessor: "onlineRunDays",
          },

          {
            Header: "Online No Run Days",
            accessor: "onlineNoRunDays",
          },
          {
            Header: "Avg No Of Trips/ Run Day",
            accessor: "averageNumberofTrips",
          },
          {
            Header: "Avg Distance (km)/ Run Day",
            accessor: "avgDisPerDay",
          },
          {
            Header: "Avg Run Time (hh:mm) /Run Day",
            accessor: "averageRuntime",
            Cell: ({ row, column, cell }) => {
              const averageRuntime = row.original.averageRuntime;

              return row.original.averageRuntime !== "" &&
                row.original.averageRuntime !== null
                ? averageRuntime
                : "00:00";
            },
          },
        ],
      },
      {
        Header: " Battery Metrics",
        columns: [
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
          {
            Header: "Move Energy Consumed (kWh)",
            accessor: "moveEnergyConsumed",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Efficiency (km/kWh)"
                    : "Efficiency (km/Wh)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Distance Traveled on days that meet above criteria /Total Energy consumed on such days."
                }
              />
            ),
            accessor: "efficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Efficiency (kWh/km)"
                    : "Efficiency (Wh/km)"
                }
                title={
                  "Energy Efficiency takes into consideration only the days on which the vehicle has traveled more than 10 kms. The formula used is Total Energy consumed on such days / Total Distance Traveled on days that meet above criteria. "
                }
              />
            ),
            accessor: "efficiencyPerKm",
          },
          {
            Header: "charging Instances",
            accessor: "manualChargingCount",
          },
          {
            Header: "Charging Duration(hh:mm)",
            accessor: "chargingMins",
          },
        ],
      },
      // {
      //   Header: " Alerts",
      //   columns: [
      //     {
      //       Header: "charging Instances",
      //       accessor: "chargingCount",
      //     },
      //     {
      //       Header: "Harsh Driving Instances",
      //       accessor: "rashDrivingInstances",
      //     },
      //   ],
      // },
    ],
    []
  );

  const columnsTractor = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "S.No",
        width:80,
        Cell: ({ row }) => (
          <Typography id={row.index}>{row.index + 1}</Typography>
        ),
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleNumber",
        width:180
      },      {
        Header: "Customer Name",
        accessor: "customerName",
       
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "Months In Service",
            accessor: "monthsinService",
          },
          {
            Header: "Start HourMeter (hh:mm)",
            accessor: "startHourMeterTime",
          },
          {
            Header: "End HourMeter (hh:mm)",
            accessor: "endHourMeterTime",
          },
          {
            Header: "Run Time  (hh:mm)",
            accessor: "hourMeterTime",
          },
          {
            Header: "Start Odometer (km)",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (km)",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled (km)",
            accessor: "tripDistance",
          },
          // {
          //   Header: "No Of Run Days",
          //   accessor: "numberofNORUNdays",
          // },
        ],
      },
      {
        Header: " Top Line Metrics / Asset Utilization",
        columns: [
         
          {
            Header: "Offline Days",
            accessor: "offlineDays",
          },
          {
            Header: "Online Run Days",
            accessor: "onlineRunDays",
          },

          {
            Header: "Online No Run Days",
            accessor: "onlineNoRunDays",
          }
        ],
      },
      {
        Header: " Battery Metrics",
        columns: [
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
                title={
                  "Total Energy Consumed including all days irrespective of distance traveled"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "charging Instances",
            accessor: "manualChargingCount",
          },
          {
            Header: "Charging Duration(hh:mm)",
            accessor: "chargingMins",
          },
        ],
      },
      // {
      //   Header: " Alerts",
      //   columns: [
      //     {
      //       Header: "charging Instances",
      //       accessor: "chargingCount",
      //     },
      //     {
      //       Header: "Harsh Driving Instances",
      //       accessor: "rashDrivingInstances",
      //     },
      //   ],
      // },
    ],
    []
  );


  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
    arr.push("efficiency");
    arr.push("stopEnergyConsumedinpark");
    arr.push("customerName");
    arr.push("moveEnergyConsumed");
    initialState1.hiddenColumns = arr;
  }

  // Get Data from New Dropdown

  //Export PDF & Excel

  const onChangeXcl = async () => {
    try {
      if(resellerId === 114663) {
        var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportVehicleWise/fleetSummaryVehicleWiseTractor.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy;
      }else {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportVehicleWise/fleetSummaryVehicleWise.xls?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy;
      }
      window.open(url);

      auditPayload.message = "Reports > MonthlySummaryVehicleWise > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";

      auditLog(auditPayload);
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportVehicleWise/fleetSummaryVehicleWise.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy;

      window.open(url);

      auditPayload.message = "Reports > MonthlySummaryVehicleWise > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";

      auditLog(auditPayload);
    } catch (error) {}
  };


  return (
    <Box>
      {/* <ReportNewDropDown
        onSubmit={handleSubmit}
        isShowVeh={true}
        displayMonth={true}
      /> */}
      {/* <FilterDropdown getData={onSubmitForm} status={false} monthPicker={true}/> */}
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardContent sx={{ py: 1 }}>
          <DataTable
            payload={payload}
            exportWithAll={resellerId === 110224 ? true : false}
            initialState={initialState1}
            columns={resellerId === 114663?columnsTractor:columns}
            data={data}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePDF}
            includeSearchBox={true}
            handleChange={handleSearch}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={2}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={rowsPerPage}
            isLoading={isLoadingOut}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default MonthlySummaryvehicleWiseReport;
